import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {deleteWithToken, getWithToken, LoginCheck, useFetchUserData} from "../../Services/DataSettings";

export function TimeEntry({ saveTracker, subjects, modules, setPlannedtime, setTotaltime, setDates}) {
    const {userId} = useFetchUserData();
    const {subject} = useParams();
    const isLoggedIn = LoginCheck();

    const [specificTimetracker, setSpecificTimetracker] = useState(null);
    const [subj, setSubj] = useState(subjects);
    const [module, setModule] = useState(modules);

    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [formData, setFormData] = useState({
        plannedtime: '',
        comment: '',
        starttime: [''],
        endtime: [''],
    });
    const [totalHour, setTotalHours] = useState('0.00');
    const [timeDifferences, setTimeDifferences] = useState([]);
    const [showSuccessMessage, setShowSuccessMeesage] = useState(false);
    const [error, setError] = useState('');

    const [currentSubject, setCurrentSubject] = useState(null);
    const [currentModule, setCurrentModule] = useState(null);
    const [ownTheme, setOwnTheme] = useState(false);

    const resetFormData = () => {
        setFormData({
            plannedtime: '',
            comment: '',
            starttime: [''],
            endtime: [''],
        });

        setPlannedtime('')
        setTotaltime('');
        setDates('');
    };

    const deleteTimetracker = async (e) => {
        e.preventDefault();

        const confirmDelete = window.confirm("Wollen sie den Eintrag wirklich löschen?");

        if (!confirmDelete) {
            return;
        }

        try {
            const res = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/timetracker/deleteTimetracker/${userId}/${subject}/${date}`);

            if (res.ok) {
                console.log({message: "Entry was deleted"});
                setShowSuccessMeesage(true);
                setTimeout(() => setShowSuccessMeesage(false), 3000);
                resetFormData();
                setTotalHours('0.00');
                setTimeDifferences([]);
                setPlannedtime('');
                setDates('')
                setTotaltime('');
            }else{
                setError("Eintrag konnte nicht gelöscht werden");
                console.error({error: "Cannot delete the entry"});
            }
        } catch (e) {
            setError(e.message);
            console.error({error: e.message});
        }
    }

    const getSpecificTimetracker = async (currentDate) => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/timetracker/getSpecificTimetracker/${userId}/${subject}/${currentDate}`);

            const data = await res.json();

            if (!res.ok){
                setError('Etwas lief schief beim Holen der Daten aus dem Backend');
                console.error({error: 'Something went wrong while fetching data from backend'});
                return;
            }

            if (data.code === 403){
                setError("Nicht autorisiert");
                console.error({error: `Authorization problem.`})
                window.location.href = "/403";
                return;
            }

            if (data.code === 500){
                setError("Server Fehler");
                console.error({error: "Server error on timetracker page getting timetrackers for setting entries"});
                window.location.href = "/500";
                return;
            }

            setFormData({
                plannedtime: data.planned_time || '',
                starttime: data.start_time || [''],
                endtime: data.end_time || [''],
                comment: data.comment || '',
            });
            setSpecificTimetracker(data);
            calculateTotalHours(data.start_time, data.end_time);

            setPlannedtime(data.planned_time || '');
            setTotaltime(data.total || '');
            setDates(data.date || '');
        }catch (e) {
            setError(e.message)
            console.error({error: e.message});
        }
    };

    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, []);
    useEffect(() => {
        if (isLoggedIn){
            getSpecificTimetracker(date);
        }else{
            setError("Nicht eingeloggt");
            console.error({error: "Not logged in"});
            localStorage.clear();
        }
    }, [isLoggedIn, userId, subject, date]);

    useEffect(() => {
        getTotalHours();
    }, [userId, subject, date, formData]);

    useEffect(() => {
        setCurrentSubject(subj.find(subjectt => subjectt && subjectt._id === subject) || null);
        setCurrentModule(module.find(modulee => modulee && modulee._id === subject) || null);

        if (subject === 'own_theme'){
            setOwnTheme(true);
        }

        setSubj(subjects);
        setModule(modules);
    }, [subj, module, subjects, modules, subject]);

    useEffect(() => {
        if (formData.starttime.every(time => time) && formData.endtime.every(time => time)){
            addTimeEntry();
        }
    }, [formData.starttime, formData.endtime]);

    const calculateTotalHours = (starttimes, endtimes) => {
        let totalHour = 0;
        const differences = [];

        starttimes.forEach((starttime, index) => {
            const start = dayjs(`${date}T${starttime}`);
            const end = dayjs(`${date}T${endtimes[index]}`);

            if (start.isValid() && end.isValid()) {
                const difference = end.diff(start, 'hour', true);
                differences.push(difference.toFixed(2));
                totalHour += difference;
            } else {
                differences.push('0.00');
            }
        });

        setTotalHours(totalHour.toFixed(2));
        setTimeDifferences(differences);
    };

    const getTotalHours = () => {
        calculateTotalHours(formData.starttime, formData.endtime)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        getTotalHours();

        const body = {
            user_id: userId,
            subject_id: currentSubject?._id || null,
            module_id: currentModule?._id || null,
            own_theme: ownTheme,
            comment: formData.comment,
            planned_time: formData.plannedtime,
            start_time: formData.starttime.filter(time => time),
            end_time: formData.endtime.filter(time => time),
            createdAt: date,
            total: totalHour,
        };

        getTotalHours();

        try {
            await saveTracker(body);
            setShowSuccessMeesage(true);
            setTimeout(() => setShowSuccessMeesage(false), 3000);
        } catch (e) {
            console.error({error: "Failed to save time tracker"});
        }

        saveTracker(body);
    }

    const handleTimeEntryChange = (index, field, value) => {
        const newTimes = [...formData[field]];
        newTimes[index] = value;
        setFormData({ ...formData, [field]: newTimes });

        setPlannedtime(formData.plannedtime);
        setTotaltime(totalHour)
        setDates(date);
    };

    const addTimeEntry = () => {
        setFormData({
            ...formData,
            starttime: [...formData.starttime, ''],
            endtime: [...formData.endtime, ''],
        });
    };

    const previousDate = (e) => {
        e.preventDefault();
        const newDate = (dayjs(date).subtract(1, "day").format('YYYY-MM-DD'));
        setDate(newDate);
        getTotalHours();
        resetFormData();
        getSpecificTimetracker(newDate);
    };

    const nextDate = (e) => {
        e.preventDefault();
        const newDate = (dayjs(date).add(1, "day").format('YYYY-MM-DD'));
        setDate(newDate);
        getTotalHours();
        resetFormData();
        getSpecificTimetracker(newDate);
    };

    if (!isLoggedIn) return window.location.href = "/login";
    const renderEntries = () => {
        //if (error) return <>{error}</>
        return (
            <>
                <div className="row">
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="date">Datum</label>
                        <div className="date-navigation">
                            <input type="date" id="date" className="date-input" value={date}
                                   onChange={(e) => {
                                       const newDate = e.target.value;
                                       setDate(newDate);
                                       getSpecificTimetracker(newDate);
                            }}/>
                            <button onClick={previousDate}>&#9664;</button>
                            <button onClick={nextDate}>&#9654;</button>
                        </div>

                        <label htmlFor="plannedtime">Geplante Zeit (in Minuten):</label>
                        <div className="plan-input">
                            <input type="text" id="plannedtime" value={formData.plannedtime}
                                   onChange={(e) => setFormData({ ...formData, plannedtime: e.target.value })} required/>
                        </div>

                        <label htmlFor="time-entry">Von-Zeit zu Bis-Zeit (von - bis):</label>
                        {formData.starttime.map((start, index) => (
                            <div className="time-row" key={index}>
                                <input type="time" id="time-entry" value={start}
                                    onChange={(e) => handleTimeEntryChange(index, 'starttime', e.target.value)} required={index === 0}/>
                                <input type="time" id="time-entry" value={formData.endtime[index]}
                                    onChange={(e) => handleTimeEntryChange(index, 'endtime', e.target.value)} required={index === 0}/>
                                <span dangerouslySetInnerHTML={{ __html: timeDifferences[index] ? `${timeDifferences[index]}` : ''}} />
                            </div>
                        ))}

                        <div className="total-hours">
                            <span>Total Stunden: </span>
                            <p></p>
                            <p></p>
                            <span id="totalHours">{totalHour}</span>
                        </div>

                        <textarea
                            className="comments-section"
                            id="comments-section"
                            value={formData.comment}
                            onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                        />

                        <button className="save-button" type="submit">Speichern</button>
                        <button className="delete-button" onClick={deleteTimetracker}>Löschen</button>
                    </form>
                </div>
                {showSuccessMessage && (
                    <div className="success-message">
                        Erfolgreich gespeichert/gelöscht
                    </div>
                )}
            </>
        );
    }

    return renderEntries();
}