import dayjs from 'dayjs';

export const ModulesList = ({modules}) => {
    return (
        <div className="row">
            {/* searches for the variables and if they are present or equal the state they are in */}
            {modules.startdate && modules.enddate && modules.state_id.state === "Geplant" || modules.state_id.state === "Abgeschlossen" ? (
                <div className="col-12 module-container">
                    <div className={GetDateClass(modules.startdate, modules.enddate)}>
                        {dayjs(modules.startdate).format("DD.MM.YY")} - {dayjs(modules.enddate).format("DD.MM.YY")}
                    </div>
                    <div className="module-title">
                        {modules.module_id.number} - {modules.module_id.name}
                    </div>
                    <div className="module-assessment">
                        {modules.assessment}
                    </div>
                    <div className="module-sequence">
                        {modules.sequence}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const GetDateClass = (startdate, enddate) => {
    if (!startdate || !enddate) {
        return "";
    }

    const today = dayjs();
    const start = dayjs(startdate);
    const end = dayjs(enddate);

    if (today.isAfter(end)) {
        // class in CSS is returned
        return "module-overdue";
    } else if (today.isAfter(start)) {
        // class in CSS is returned
        return "module-inprogress";
    } else {
        return "";
    }
};