import dayjs from "dayjs";
import {Workday} from "./Workday";

const getMonday = (d) => {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
};

export const Workdays = ({ startdate, workdays: selectedWorkdays }) => {
    const monday = dayjs(getMonday(startdate));
    const tuesday = dayjs(monday).add(1, "d");
    const wednesday = dayjs(monday).add(2, "d");
    const thursday = dayjs(monday).add(3, "d");
    const friday = dayjs(monday).add(4, "d");

    return (
        <div className="workdays">
            <Workday date={monday} weekday="Mo" workdays={selectedWorkdays}/>
            <Workday date={tuesday} weekday="Di" workdays={selectedWorkdays}/>
            <Workday date={wednesday} weekday="Mi" workdays={selectedWorkdays}/>
            <Workday date={thursday} weekday="Do" workdays={selectedWorkdays}/>
            <Workday date={friday} weekday="Fr" workdays={selectedWorkdays}/>
        </div>
    );
};