// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration {
    margin: 60px auto;
    padding: 20px;
    border-radius: 10px;
}

.registration a {
    color: #191033;
}

.registration-img {
    margin-top: 25vh;
    margin-left: 12vw;
}

.registration input {
    width: 100%;
}

.registration select{
    width: 100%;
    height: 30px;
    margin-bottom: 3px;
    border-radius: 3px;
}

.registration button {
    width: 100%;
    background-color: #932432;
    border: none;
    color: #fff;
    padding: 5px;
    transition: transform 0.3s ease-in-out;
}

.registration button:hover {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/css/registration.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".registration {\r\n    margin: 60px auto;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n}\r\n\r\n.registration a {\r\n    color: #191033;\r\n}\r\n\r\n.registration-img {\r\n    margin-top: 25vh;\r\n    margin-left: 12vw;\r\n}\r\n\r\n.registration input {\r\n    width: 100%;\r\n}\r\n\r\n.registration select{\r\n    width: 100%;\r\n    height: 30px;\r\n    margin-bottom: 3px;\r\n    border-radius: 3px;\r\n}\r\n\r\n.registration button {\r\n    width: 100%;\r\n    background-color: #932432;\r\n    border: none;\r\n    color: #fff;\r\n    padding: 5px;\r\n    transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.registration button:hover {\r\n    transform: scale(1.1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
