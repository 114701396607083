import React, {useEffect, useRef, useState} from "react";
import Confetti from 'react-confetti';
import {Moduldetail} from "../Moduldetails/Moduldetail";
import {Infomessage} from "../Navigation/infomessage";
import {
    CheckAdmin,
    CheckModerator, getWithToken,
    LoginCheck, putWithToken,
    useFetchUserData,
} from "../Services/DataSettings";
import {DndContext, rectIntersection} from "@dnd-kit/core";
import {ListingAllModules} from "./ListingAllModules";
import {Calendar} from "./TablePlanner/Calendar";

export function Modulplanner() {
    const isModerator = CheckModerator();
    const isAdmin = CheckAdmin();
    const isLoggedIn = LoginCheck();

    const {userId} = useFetchUserData();

    const [showConfetti, setShowConfetti] = useState(false);
    const [studentId, setStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [states, setStates] = useState([]);
    const [initialModulecard, setInitialModulecard] = useState([]);
    const [workdays, setWorkdays] = useState([]);
    const [selectedModulecard, setSelectedModulecard] = useState([]);
    const messageRef = useRef(null);
    const [rerender, setRerender] = useState(false);
    const [allstudents, setAllStudents] = useState([]);
    const [holidays, setHoliday] = useState([]);

    const loadStates = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/states`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on planner page getting states"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setStates(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    const loadAllStudentsForTeachers = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/mod/students`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on planner page getting students for teacher"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setAllStudents(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    const loadModulecard = async (uid) => {
        if (uid === null || uid.toString() === "" || ((isModerator || isAdmin) && userId.toString() === uid.toString())) return;

        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/modulecard/${uid}`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on planner page getting modulecards"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setInitialModulecard(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    const loadWorkdays = async (uid) => {
        if (uid === null || uid.toString() === "" || ((isModerator || isAdmin) && userId.toString() === uid.toString())) return;

        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/workdays/${uid}`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on planner page getting workdays"});
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setWorkdays(data);
        } catch (e) {
            setError(e);
        }
    };

    const loadHolidays = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/holidays`);
            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on planner page getting workdays"});
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setHoliday(data)
        } catch (e) {
            setError(e);
            console.log({error: "Something went wrong while fetching holiday data"});
        }
    }

    useEffect(() => {
        renderModulplanner();
    }, [rerender, studentId]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadHolidays()
                await loadModulecard(studentId);
                await loadWorkdays(studentId);
                setIsLoaded(true);
            } catch (e) {
                setError(e);
            }
        }
        fetchData();

    }, [studentId]);
    useEffect(() => {
        modHeader();
    }, [allstudents]);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim ITSupport mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadStates();
                if (isLoggedIn) {
                    if (!isModerator || !isAdmin) {
                        await loadHolidays();
                        await loadModulecard(userId);
                        await loadWorkdays(userId);
                        setIsLoaded(true);
                    } else {
                        setIsLoaded(false);
                    }
                } else {
                    setError("Der Benutzer ist nicht eingeloggt.");
                }
            } catch (e) {
                setError(e);
                setIsLoaded(false);
            }
        };

        fetchData();
    }, [userId, isLoggedIn, isModerator, isAdmin]);
    useEffect(() => {
        if (isModerator) {
            loadAllStudentsForTeachers();
        }
    }, [isModerator]);

    const saveModulecard = async (modulcardId, body) => {
        try{
            messageRef.current.classList.add('show');
            const response = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/modulecard/save-modulecard/${modulcardId}`, body)

            if (!response.ok){
                console.error('Failed to save');
                alert('Etwas ist schiefgelaufen beim Speichern');
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
            messageRef.current.classList.remove('show');
        }catch (e) {
            console.error('Error:', e.message);
            alert("Leider gab es einen Fehler beim Speichern. Bitte fülle alle Felder aus.");
            setError(e.message);
            messageRef.current.classList.remove('show');
        }
    };

    const selectModule = (id) => {
        const newSelectedModulecard = initialModulecard.find((modulecard) => {
            return modulecard._id === id;
        });
        if (newSelectedModulecard) {
            setSelectedModulecard({});
            setSelectedModulecard(newSelectedModulecard);
        } else {
            console.log("ID not found");
        }
    };

    const updateModulecard = (updatedCard) => {
        if (updatedCard === undefined) {
            setSelectedModulecard({});
        }
        setSelectedModulecard(updatedCard);

        initialModulecard.map((modulecard) => {
            if (modulecard._id === updatedCard._id) {
                return modulecard = updatedCard;
            } else {
                return initialModulecard;
            }
        });
        setRerender(!rerender);
    };

    const updateModulecardState = (moduleCardId, newState) => {
        const selectedState = states.find((state) => state.state === newState)

        setInitialModulecard((prevModulecards) => {
            return prevModulecards.map((modulecard) => {
                if (modulecard._id === moduleCardId && modulecard.state_id.state !== selectedState.state){
                    const updateModulecard = {
                        ...modulecard,
                        state_id: {
                            ...modulecard.state_id,
                            state: selectedState.state,
                            _id: selectedState._id
                        },
                    };
                    saveModulecard(moduleCardId, updateModulecard);

                    if (selectedState.state === "Abgeschlossen") {
                        setShowConfetti(true);
                        setTimeout(() => setShowConfetti(false), 5000); // show Confetti for 5 seconds
                    }

                    return updateModulecard;
                }
                return modulecard;
            });
        });
    };

    const modHeader = () => {
        if (isModerator) {
            return (
                <div className="studentselector form-group">
                    <select className="col-6 form-control" id="student" onChange={(e) => {
                        setStudentId(e.target.value);
                        loadWorkdays(e.target.value);
                        loadModulecard(e.target.value);
                    }}>
                        <option value="">Wähle einen Lernenden</option>
                        {allstudents.map((student) => (
                            <option value={student._id}>
                                {student.name} {student.surname}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }
    };

    const renderModulplanner = () => {
        return (
            <>
                <div className="modulplanner container-fluid">
                    <Infomessage reference={messageRef} info="saving"/>

                    <div className="row">
                        <DndContext
                            collisionDetection={rectIntersection}
                            onDragEnd={(e) => {
                                if (e.over) {
                                    updateModulecardState(e.active.id, e.over?.id);
                                } else {
                                    console.log("id is null");
                                }
                            }}
                        >
                            <ListingAllModules
                                key="key-offen"
                                modulecards={initialModulecard}
                                state="Offen"
                                col="col-3 col-xl-4"
                                selectModule={selectModule}
                                comparator={(a, b) => a.module_id.year - b.module_id.year}
                            />
                            <Calendar
                                modulecards={initialModulecard}
                                state="Geplant"
                                col="col-6 col-xl-4"
                                selectModule={selectModule}
                                comparator={(a, b) => new Date(a.startdate).getTime() - new Date(b.startdate).getTime()}
                                workdays={workdays}
                                key="key-geplant"
                                holidays={holidays}
                            />
                            <ListingAllModules
                                modulecards={initialModulecard}
                                state="Abgeschlossen"
                                col="col-3 col-xl-4"
                                selectModule={selectModule}
                                comparator={(a, b) => new Date(a.enddate).getTime() - new Date(b.enddate).getTime()}
                                key="key-abgeschlossen"
                            />
                            <Moduldetail
                                cards={selectedModulecard}
                                saveModulecard={saveModulecard}
                                onCardChange={updateModulecard}
                            />
                        </DndContext>
                    </div>
                </div>
            </>
        );
    };

    if (error) {
        return <>Error: {error}</>;
    } else if (!isLoaded) {
        if (isModerator){
            return (
                <>
                    {modHeader()}
                    <div className="loadinginfo">Wähle einen Lernenden</div>
                </>
            );
        }else{
            return (
                <>
                    <div className="loading"></div>
                </>
            )
        }
    } else {
        return (
            <>
                {modHeader()}
                {showConfetti && <Confetti />}
                {renderModulplanner()}
            </>
        );
    }
}