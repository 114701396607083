import {useDroppable} from "@dnd-kit/core";
import {PrepareModulesList} from "./PrepareModulesList";

export function ListingAllModules({ modulecards, state, col, selectModule, comparator}){
    const {setNodeRef} = useDroppable({
        id: `${state}`,
    });
    if (!comparator){
        comparator = (a, b) => a.module_id.number - b.module_id.number;
    }
    return (
        <div ref={setNodeRef} className={`state state-${state} ${col}`}>
            <h2>
                {state} (
                {
                    modulecards.filter(function (card){
                        return card.state_id.state === state;
                    }).length
                }
                )
            </h2>
            <div className="modules">
                {modulecards.filter(function (modulecard){
                    return modulecard.state_id.state === state;
                })
                    .sort((a, b) => comparator(a, b))
                    .map((modulecard) => (
                        <PrepareModulesList modulecard={modulecard} key={modulecard.module_id._id} selectModule={selectModule}/>
                    ))}
            </div>
        </div>
    );
}