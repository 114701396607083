import React, {useState, useEffect} from "react";
import {LoginCheck, postWithToken} from "../../Services/DataSettings";
import {useNavigate} from "react-router-dom";

/**
 * sends the data to the backend
 *
 * @param moduleData - the data sent from the frontend to the backend
 * @return {Promise<Response>} - success message in boolean
 */
async function createModule(moduleData) {
    return postWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/createModule`, moduleData);
}

export const CreateModules = () => {
    const isLoggedIn = LoginCheck();

    const navigate = useNavigate();

    const [specialisation, setSpecialisation] = useState([]);
    const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [year, setYear] = useState(0);
    const [actionskills, setActionskills] = useState("");
    const [skill, setSkill] = useState("");
    const [object, setObject] = useState("");
    const [remarks, setRemarks] = useState("");
    const [type, setType] = useState("");
    const [dependency, setDependency] = useState("");
    const [error, setError] = useState("");

    /**
     *  gets all specialisations from the database
     */
    const getSpecialisations = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/specialisations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting specialisations for creating modules"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setSpecialisation(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    //loads all data based on dependency
    useEffect(() => {
        getSpecialisations();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            getSpecialisations();
        } else {
            setError("Der Benutzer ist nicht eingeloggt")
        }
    }, [isLoggedIn]);

    /**
     * button handler for the form
     * @param e - event handler
     */
    const handleCreate = async (e) => {
        e.preventDefault();

        const isCreated = await createModule({
            specialisation,
            selectedSpecialisation,
            name,
            number,
            year,
            actionskills,
            skill,
            object,
            remarks,
            type,
            dependency,
        });

        if (isCreated) {
            console.log({message: "Module created"});
            alert("Modul wurde erfolgreich erstellt");
        } else {
            console.error({error: "Module couldn't be created"});
            setError("Modul konnte nicht erstellt werden.");
        }
    };

    //checks for error and returns HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="module-creation__container">
                    <div className="row">
                        <div className="administration col-1 col-md"><img className="login-img" src="/modules.gif"
                                                                          alt="modules"/></div>
                        <div className="administration col-10 col-md">
                            <h1>Module erstellen</h1>
                            <form onSubmit={handleCreate}>
                                <h5>Name des Moduls*</h5>
                                <input type="text" onChange={(e) => setName(e.target.value)} required/>

                                <h5>Nummer des Moduls*</h5>
                                <input type="text" onChange={(e) => setNumber(e.target.value)} required/>

                                <h5>Lehrgang des Moduls*</h5>
                                <select value={selectedSpecialisation}
                                        onChange={(e) => setSelectedSpecialisation(e.target.value)} required>
                                    <option value="">Lehrgang aussuchen</option>
                                    {specialisation.map((specs) => (
                                        <option key={specs._id} value={specs._id}>
                                            {specs.specialisation}
                                        </option>
                                    ))}
                                </select>

                                <h5>Lehrjahr des Moduls*</h5>
                                <input type="number" onChange={(e) => setYear(parseInt(e.target.value))} required/>

                                <h5>Actionskills für das Modul*</h5>
                                <input type="text" onChange={(e) => setActionskills(e.target.value)} required/>

                                <h5>Skills für das Modul*</h5>
                                <input type="text" onChange={(e) => setSkill(e.target.value)} required/>

                                <h5>Beschreibung für das Modul*</h5>
                                <input type="text" onChange={(e) => setObject(e.target.value)} required/>

                                <h5>Bemerkungen zum Modul</h5>
                                <input type="text" onChange={(e) => setRemarks(e.target.value)}/>

                                <h5>Typ des Moduls*</h5>
                                <input type="text" onChange={(e) => setType(e.target.value)} required/>

                                <h5>Abhängigkeiten zum Modul</h5>
                                <input type="text" onChange={(e) => setDependency(e.target.value)}/>

                                <p>Alle mit * markierten Felder müssen ausgefüllt werden</p>

                                <button type="submit">Erstellen</button>
                                <button className="go-back" onClick={() => navigate('/administration/modules')}>Zurück zur Liste</button>
                            </form>
                        </div>
                        <div className="administration col-1 col-md"/>
                    </div>
                </div>
            </>
        );
    }
}