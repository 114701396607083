// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.startdate {
    font-size: 0.8em;
    color: #383838;
}
span.enddate {
    font-size: 0.8em;
    color:#383838;
}`, "",{"version":3,"sources":["webpack://./src/css/dates.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":["span.startdate {\r\n    font-size: 0.8em;\r\n    color: #383838;\r\n}\r\nspan.enddate {\r\n    font-size: 0.8em;\r\n    color:#383838;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
