// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module-studentname,
.trackers-studentname {
    border-bottom: 2px solid #932432;
    color: #191033;
    padding: 3px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
}

.module-studentname .btn {
    float: right;
}

.module-container,
.tracker-container {
    display: grid;
    border: none;
    font-size: 0.8em;
    grid-template-columns: repeat(4, 2fr);
    gap: normal;
    padding: 15px;
}

.module-container div,
.tracker-container div {
    padding: 10px;
    border-bottom: 1px solid #283747;
}

.module-title,
.tracker-title {
    font-weight: bold;
}

.module-overdue {
    color: #932432;
}

.module-inprogress {
    color: #5a9324;
    font-weight: bold;
}

.studentselector {
    padding: 10px 50px;
    margin-top: 5px;
    color: #f9f9f8;
}`, "",{"version":3,"sources":["webpack://./src/css/moderator.css"],"names":[],"mappings":"AAAA;;IAEI,gCAAgC;IAChC,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,qCAAqC;IACrC,WAAW;IACX,aAAa;AACjB;;AAEA;;IAEI,aAAa;IACb,gCAAgC;AACpC;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".module-studentname,\r\n.trackers-studentname {\r\n    border-bottom: 2px solid #932432;\r\n    color: #191033;\r\n    padding: 3px;\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n    margin-top: 20px;\r\n}\r\n\r\n.module-studentname .btn {\r\n    float: right;\r\n}\r\n\r\n.module-container,\r\n.tracker-container {\r\n    display: grid;\r\n    border: none;\r\n    font-size: 0.8em;\r\n    grid-template-columns: repeat(4, 2fr);\r\n    gap: normal;\r\n    padding: 15px;\r\n}\r\n\r\n.module-container div,\r\n.tracker-container div {\r\n    padding: 10px;\r\n    border-bottom: 1px solid #283747;\r\n}\r\n\r\n.module-title,\r\n.tracker-title {\r\n    font-weight: bold;\r\n}\r\n\r\n.module-overdue {\r\n    color: #932432;\r\n}\r\n\r\n.module-inprogress {\r\n    color: #5a9324;\r\n    font-weight: bold;\r\n}\r\n\r\n.studentselector {\r\n    padding: 10px 50px;\r\n    margin-top: 5px;\r\n    color: #f9f9f8;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
