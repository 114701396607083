import {useNavigate} from "react-router-dom";
import {CheckAdmin, LoginCheck, useGetUserData} from "../Services/DataSettings";
export const WebsiteAdministration = () => {
    const navigate = useNavigate();
    const {data} = useGetUserData();
    const isAdmin = CheckAdmin();

    /**
     * checks if user is logged in
     * checks if it is an admin
     *
     * @return HTMLBodyElement - returns a whole body for the GUI. Either error or the website
     */
    if (!LoginCheck()) window.location.href = "/login";
    if (isAdmin) {
        return (
            <div className="administration__container">
                <div className="row">
                    <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif" alt="modules"/></div>
                    <div className="administration col-10 col-md-2">
                        <h1>Willkommen {data.name} - Website-Administration</h1>
                        <h5>Wähle eine Sicht aus</h5>
                        <button onClick={() => navigate('/administration/users')}>Nutzerliste</button>
                        <button onClick={() => navigate('/administration/modules')}>Modulliste</button>
                        <button onClick={() => navigate('/administration/holidays')}>Ferien</button>
                    </div>
                    <div className="administration col-1 col-md-2"/>
                </div>
            </div>
        );
    }else{
        return <>Fehler: Keine Berechtigung</>;
    }
}