import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {deleteWithToken, getWithToken, LoginCheck, putWithToken} from "../../Services/DataSettings";

async function editHoliday(holiday, id) {
    const res = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/editHoliday/${id}`, holiday);

    return res.ok;
}

export const Edit = () => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [type, setType] = useState('');

    const [holidays, setHolidays] = useState([]);

    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);

    const navigate = useNavigate();
    const isLoggedIn = LoginCheck();
    const {holiday} = useParams();

    const getHolidays = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/holidays/${holiday}`);
            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting users for a list"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setHolidays(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    const deleteHoliday = async (e) => {
        e.preventDefault();
        const res = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/deleteHoliday/${holiday}`);

        if (!res.ok) {
            console.log({error: "Error while deleting"});
            setError("Ein Problem beim Löschen ist aufgetreten");
        }

        console.log({message: "Successfully deleted"});
        await setSuccessMessage(true);
        setTimeout(() => setSuccessMessage(false), 3000);
        setTimeout(() => window.location.href = "/administration/holidays", 6000);
    }

    useEffect(() => {
        if (isLoggedIn) {
            getHolidays();
        } else {
            setError("Benutzer ist nicht eingeloggt.");
        }
    }, [isLoggedIn]);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, []);
    useEffect(() => {
        if (holidays) {
            setFrom(dayjs(holidays.from).format('YYYY-MM-DD'));
            setTo(dayjs(holidays.to).format('YYYY-MM-DD'));
            setType(holidays.type);
        }
    }, [holidays]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isEdited = await editHoliday({
            from,
            to,
            type,
        }, holiday);

        if (!isEdited) {
            console.error({error: "There was an error while editing holiday"});
            setError("Da lief was beim Ändern der Feriendaten schief.");
            return;
        }

        console.log({message: "Successfully saved holiday"});
        setSuccessMessage(true);
        setTimeout(() => setSuccessMessage(false), 3000);
    }

    if (error) {
        return (
            <>
                Fehler: {error}
            </>
        );
    }
    if (!isLoggedIn) {
        return (
            <>
                Nicht eingeloggt
                <a href="/login">Login</a>
            </>
        );
    }
    return (
        <>
            <div className="edit-holiday__container">
                <div className="row">
                    <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif"
                                                                        alt="modules"/></div>
                    <div className="administration col-10 col-md-8">
                        <h1>Schulferien bearbeiten</h1>
                        <form onSubmit={handleSubmit}>
                            <h5>Schulferien Start</h5>
                            <input type="date" placeholder={dayjs(holidays.from).format('YYYY-MM-DD')}
                                   onChange={(e) => setFrom(e.target.value)} required/>

                            <h5>Schulferien Ende</h5>
                            <input type="date" placeholder={dayjs(holidays.to).format('YYYY-MM-DD')}
                                   onChange={(e) => setTo(e.target.value)} required/>

                            <h5>Art der Ferien</h5>
                            <input type="text" placeholder={holidays.type}
                                   onChange={(e) => setType(e.target.value)}/>

                            <button type="submit">Speichern</button>
                            <button onClick={deleteHoliday}>Löschen</button>
                            <button className="go-back" onClick={() => navigate('/administration/holidays')}>Zurück zur Liste</button>
                        </form>
                    </div>
                    <div className="administration col-1 col-md-2"></div>
                </div>
            </div>
            {successMessage && (
                <div className="success-message">
                    Erfolgreich geändert/gelöscht
                </div>
            )}
        </>
    );
}