import React, {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {deleteWithToken, getWithToken, LoginCheck, putWithToken} from "../../Services/DataSettings";

export const DetailsUsers = () => {
    const {user} = useParams();
    const isLoggedIn = LoginCheck();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [specialisation, setSpecialisation] = useState([]);
    const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [error, setError] = useState("");

    /**
     *  gets all users from the database
     */
    const getUser = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/users/${user}`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting users for details"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setUsers(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     * gets all specialisation from database
     */
    const getSpecialisations = async () => {
        try{
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/specialisations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting specialisations for details"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setSpecialisation(data);
        }catch (e) {
            console.error({error: e.message});
            setError("Da lief etwas falsch beim Holen der Spezialisierungsdaten");
        }
    };

    //loads data once until successful or based on dependency
    useEffect(() => {
        getSpecialisations();
    }, []);
    useEffect(() => {
        getUser();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            getSpecialisations();
            getUser();
        }else{
            setError("Der Benutzer ist nicht angemeldet");
        }
    }, [isLoggedIn]);

    /**
     * sends the action to delete the user in the backend
     */
    const deleteUser = async () => {
        const confirmDelete = window.confirm('Bist du sicher, dass du den Nutzer mit all seinen Daten löschen möchtest?');

        if (!confirmDelete){
            return;
        }

        try {
            const res = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/deleteUser/${user}`);

            if (!res.ok) {
                console.error({error: "Cannot delete the user"});
                setError("Nutzer kann nicht gelöscht werden");
            }else{
                console.log({message: "User was deleted"});
                alert("Nutzer wurde gelöscht");
                window.location.href = "/administration/users";
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     * It sends the data to be edited to the backend
     *
     * @param editedUsers - data to be edited
     * @return {Promise<boolean>} - returns success or failure
     */
    const editUser = async (editedUsers) => {
        try{
            const res = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/editUser/${users._id}`, editedUsers);

            if (!res.ok) {
                console.error({error: "Cannot edit user"});
                setError("Konnte den Nutzer nicht bearbeiten");
            }else{
                const updatedUser = await res.json();
                setUsers(updatedUser);
                return true;
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     * form handler for an edit
     *
     * @param e - event handler
     */
    const handleEdit = async (e) => {
        e.preventDefault();

        const isEdited = await editUser({
            specialisation,
            selectedSpecialisation,
            email,
            name,
            surname,
        });

        if (isEdited) {
            console.log({message: "User edited"});
            alert("Nutzer wurde bearbeitet");
            window.location.reload();
        } else {
            console.error({error: "User couldn't be edited"});
            setError("Nutzer konnte nicht bearbeitet werden");
        }
    }

    //checks for error and returns HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="user-detail__container">
                    <div className="row">
                        <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif" alt="modules"/></div>
                        <div className="administration col-10 col-md-4">
                            <h1>Nutzerdetails</h1>
                            <form onSubmit={handleEdit}>
                                <h5>Vorname</h5>
                                <input type="text" placeholder={users && users.name}
                                       onChange={(e) => setName(e.target.value)}/>

                                <h5>Nachname</h5>
                                <input type="text" placeholder={users && users.surname}
                                       onChange={(e) => setSurname(e.target.value)}/>

                                <h5>Ausbildung</h5>
                                <select value={selectedSpecialisation}
                                        onChange={(e) => setSelectedSpecialisation(e.target.value)}>
                                    <option value="">Momentane
                                        Ausbildung: {users && users.specialisation_id ? users.specialisation_id.specialisation : ''}</option>
                                    {specialisation.map((specialisations) => (
                                        <option key={specialisations._id} value={specialisations._id}>
                                            {specialisations.specialisation}
                                        </option>
                                    ))}
                                </select>

                                <h5>E-Mail</h5>
                                <input type="email" placeholder={users && users.email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                                <br/><br/>
                                <a href={`/administration/users/detail/${user}/change-password`}>Passwort ändern</a>
                                <br/>
                                <a href={`/administration/users/detail/${user}/manage-roles`}>Rollen bearbeiten</a>

                                <br/><br/>

                                <button type="submit">Speichern</button>
                                <button onClick={deleteUser}>Löschen</button>
                                <button className="go-back" onClick={() => navigate('/administration/modules ')}>Zurück zur Liste</button>
                            </form>
                        </div>
                        <div className="administration col-1 col-md-2"/>
                    </div>
                </div>
            </>
        );
    }
}