import React, {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {postWithToken} from "../../Services/DataSettings";

export const ChangePassword = () => {
    const {user} = useParams();
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState("")
    const [passwordValidation, setPasswordValidation] = useState({
        minValueValidation: false,
        numberValidation: false,
        capitalLetterValidation: false,
        specialCharacterValidation: false,
    });

    /**
     *
     * @param credentials - new password sent to backend
     * @return {Promise<boolean>} - returns success or failure
     */
    const changingPassword = async (credentials) => {
        const res = await postWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/change-password/${user}`, credentials);

        if (!res.ok) {
            console.error({error: "Cannot change password"});
            setError("Konnte das Passwort nicht ändern");
        } else {
            return true;
        }
    };

    /**
     * checks for a regex pattern in the password
     *
     * @param password - password of the user
     */
    const validatePassword = (password) => {
        setPasswordValidation({
            minValueValidation: password.length >= 8,
            numberValidation: /\d/.test(password),
            capitalLetterValidation: /[A-Z]/.test(password),
            specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
        });
    };

    /**
     * sets new password and validates the password
     *
     * @param e - event handler
     */
    const handlePasswordSafety = (e) => {
        const checkedPassword = e.target.value;
        setNewPassword(checkedPassword);
        validatePassword(checkedPassword);
    };

    /**
     * form submit handler for changing password
     *
     * @param e - event handler
     */
    const handleSubmit = async e => {
        e.preventDefault();

        const passwordChanged = await changingPassword({
            newPassword,
            confirmNewPassword
        });

        if (passwordChanged) {
            console.log("Password changed");
            alert("Passwort wurde geändert");
            window.location.reload();
        } else {
            console.log({error: "Not able to change password"});
            setError("Passwort konnte nicht geändert werden");
        }
    }

    //loads data once until successful or based on dependency
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);

    //checks for error and returns HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <div className="row">
                <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif"
                                                                    alt="modules"/></div>
                <div className="administration col-10 col-md-4">
                    <div className="administration__container">
                        <h1>Passwort Ändern</h1>
                        <form onSubmit={handleSubmit}>
                            <h5>Neues Passwort *</h5>
                            <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}"
                                   onChange={handlePasswordSafety} required/>

                            <h5>Passwort bestätigen *</h5>
                            <input type="password" onChange={e => setConfirmNewPassword(e.target.value)} required/>
                            <p>Alle mit * markierten Felder müssen ausgefüllt werden</p>

                            {Object.entries(passwordValidation).map(([key, value]) => (
                                <div key={key}>
                                    {value ? (
                                        <>
                                            ✔️
                                        </>
                                    ) : (
                                        <>
                                            ❌
                                        </>
                                    )}
                                    {key === 'minValueValidation' && 'Passwort muss mindestens 8 Zeichen lang sein'}
                                    {key === 'numberValidation' && 'Passwort muss mindestens eine Zahl enthalten'}
                                    {key === 'capitalLetterValidation' && 'Passwort muss muss mindestens einen Grossbuchstaben haben'}
                                    {key === 'specialCharacterValidation' && 'Passwort muss mindestens ein besonderes Zeichen enthalten'}
                                </div>
                            ))}

                            <br/><br/>

                            <button type="submit">Speichern</button>
                            <button className="go-back" onClick={() => navigate('/administration/users')}>Zurück zur Liste</button>
                        </form>
                    </div>
                </div>
                <div className="changepassword col-1 col-md-2"/>
            </div>
        );
    }
}