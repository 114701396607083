import {useDraggable} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";
import React, { useRef } from "react";
import {LoginCheck} from "../Services/DataSettings";

export function PrepareModulesList({modulecard, selectModule}) {
    const modulName = modulecard.module_id.name;

    const modulecardId = modulecard._id;
    const modulref = useRef(null);

    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: modulecardId
    });
    const style = {
        transform: CSS.Translate.toString(transform),
    };

    const getTruncatedName = (modulename, height) => {
        if (height > 3 * 17){
            return modulename;
        }else{
            if (modulName > 50) {
                return modulename.substring(0, 50) + "...";
            } else {
                return modulename;
            }
        }
    };

    const showDetail = () => {
        selectModule(modulecard._id);
    };

    const getTypeClass = (type) => {
        switch (type) {
            case "Schul-Pflicht":
                return "schulpflicht";
            case "Wahl-ÜK":
                return "wahluek";
            case "ÜK":
                return "uek";
            default:
                return "";
        }
    };

    const getTypeName = (type) => {
        switch (type) {
            case "Schul-Pflicht":
                return "";
            case "Wahl-ÜK":
                return "(wÜK)";
            case "ÜK":
                return "(ÜK)";
            default:
                return "";
        }
    };
    let col = "col-12";
    if (
        modulecard.state_id.state === "Offen" ||
        modulecard.state_id.state === "Abgeschlossen"
    ) {
        col = "col-xl-6 col-12";
    }

    //let dependencies = modulecard.module_id.dependency; // no dependencies yet

    if (!LoginCheck) window.location.href = "/login"; // go to /login if not logged in
    return (
        <>
            <div
                style={{transform: style.transform}}
                onClick={showDetail}
                ref={setNodeRef}
                className={`modul card ${col} ${getTypeClass(modulecard.module_id.type)}`}
                id={`sm-${modulecardId}`}
            >
                <div className="card-body" ref={modulref} id={modulecardId}>
                    <h6 className="card-subtitle mb-2">
                        {modulecard.module_id.number + " "} |
                        {" " + getTruncatedName(modulecard.module_id.name, modulecard.height)}{" "}
                        {getTypeName(modulecard.module_id.type)}
                    </h6>
                </div>
                <div className="draggable" {...listeners} {...attributes}>
                    ..
                </div>
            </div>
        </>
    );
}