import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {deleteWithToken, fetchWithToken, getWithToken, LoginCheck, putWithToken} from "../../Services/DataSettings";
import {useNavigate} from "react-router-dom";

export const DetailModules = () => {
    const {module} = useParams();
    const isLoggedIn = LoginCheck();
    const navigate = useNavigate();

    const [modules, setModules] = useState([]);

    const [specialisation, setSpecialisation] = useState([]);
    const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [year, setYear] = useState(0);
    const [actionskills, setActionskills] = useState("");
    const [skill, setSkill] = useState("");
    const [object, setObject] = useState("");
    const [remarks, setRemarks] = useState("");
    const [type, setType] = useState("");
    const [dependency, setDependency] = useState("");
    const [error, setError] = useState("");

    /**
     * gets all modules from the database
     */
    const getModule = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/modules/${module}`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting modules for details"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setModules(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     *  gets all specialisations from the database
     */
    const getSpecialisations = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/specialisations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting specialisations for details"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setSpecialisation(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     * loads data until successful or based on a change of the dependency
     */
    useEffect(() => {
        getModule();
    }, []);
    useEffect(() => {
        getSpecialisations();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            getModule();
            getSpecialisations()
        } else {
            setError("Der Benutzer ist nicht eingeloggt");
        }
    }, [isLoggedIn]);

    /**
     * deletes the data from the backend
     *
     * @return {Promise<void>} - nothing in return
     */
    const deleteModule = async () => {
        const confirmDelete = window.confirm('Bist du sicher, dass du das Modul löschen möchtest?');

        if (!confirmDelete){
            return;
        }

        try {
            const res = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/deleteModule/${module}`);

            if (!res.ok){
                console.error({error: "Couldn't delete the user"});
                setError("Das Modul konnte nicht gelöscht werden");
            }else{
                console.log({message: "User was deleted"});
                alert("Modul wurde erfolgreich gelöscht");
                window.location.href = "/administration/modules";
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    /**
     * sends the data to be edited to the backend
     *
     * @param editedModule
     * @return {Promise<boolean>} - when true edit successful, when false edit failed
     */
    const editModule = async (editedModule) => {
        try {
            const res = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/editModule/${modules._id}`, editedModule);

            // with help of ChatGPT
            if (!res.ok){
                console.error({error: "Couldn't edit module"});
                setError("Konnte nicht editieren");
            } else {
                const updatedModule = await res.json();
                setModules(updatedModule)
                return true;
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    /**
     * handles the submit button from the form
     *
     * @param e - event handler
     * @return {Promise<void>} - nothing returned
     */
    const handleEdit = async (e) => {
        e.preventDefault();

        const isEdited = await editModule({
            specialisation,
            selectedSpecialisation,
            name,
            number,
            year,
            actionskills,
            skill,
            object,
            remarks,
            type,
            dependency,
        });

        if (isEdited) {
            console.log({message: "Module edited"});
            alert("Modul wurde bearbeitet");
            window.location.reload();
        } else {
            console.error({error: "Module couldn't be edited"});
            setError("Modul konnte nicht bearbeitet werden");
        }
    };

    /**
     * checks for an error
     * @return HTMLBodyElement - returns error or the web page (list of modules)
     */
    if (error) {
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="module-detail__container">
                    <div className="row">
                        <div className="administration col-1 col-md"><img className="login-img" src="/modules.gif"
                                                                            alt="modules"/></div>
                        <div className="administration col-10 col-md">
                            <h1>Moduldetails</h1>
                            <form onSubmit={handleEdit}>
                                <h5>Name des Moduls</h5>
                                <input type="text" placeholder={modules && modules.name}
                                       onChange={(e) => setName(e.target.value)}/>

                                <h5>Nummer des Moduls</h5>
                                <input type="text" placeholder={modules && modules.number}
                                       onChange={(e) => setNumber(e.target.value)}/>

                                <h5>Lehrgang des Moduls</h5>
                                <select value={selectedSpecialisation}
                                        onChange={(e) => setSelectedSpecialisation(e.target.value)}>
                                    <option value="">Momentaner
                                        Lehrgang: {modules && modules.specialisation_id && modules.specialisation_id.specialisation}</option>
                                    {/* with help of ChatGPT */}
                                    {specialisation.map((specs) => (
                                        <option key={specs._id} value={specs._id}>
                                            {specs.specialisation}
                                        </option>
                                    ))}
                                </select>

                                <h5>Lehrjahr des Moduls</h5>
                                <input type="number" placeholder={modules && modules.year}
                                       onChange={(e) => setYear(parseInt(e.target.value))}/>

                                <h5>Actionskills für das Modul</h5>
                                <input type="text" placeholder={modules && modules.actionskills}
                                       onChange={(e) => setActionskills(e.target.value)}/>

                                <h5>Skills für das Modul</h5>
                                <input type="text" placeholder={modules && modules.skill}
                                       onChange={(e) => setSkill(e.target.value)}/>

                                <h5>Beschreibung für das Modul</h5>
                                <input type="text" placeholder={modules && modules.object}
                                       onChange={(e) => setObject(e.target.value)}/>

                                <h5>Bemerkungen zum Modul</h5>
                                <input type="text" placeholder={modules && modules.remarks}
                                       onChange={(e) => setRemarks(e.target.value)}/>

                                <h5>Typ des Moduls</h5>
                                <input type="text" placeholder={modules && modules.type}
                                       onChange={(e) => setType(e.target.value)}/>

                                <h5>Abhängigkeiten zum Modul</h5>
                                <input type="text" placeholder={modules && modules.dependency}
                                       onChange={(e) => setDependency(e.target.value)}/>

                                <br/><br/>

                                <button type="submit">Speichern</button>
                                <button onClick={deleteModule}>Löschen</button>
                                <button className="go-back" onClick={() => navigate('/administration/modules')}>Zurück zur Liste</button>
                            </form>
                        </div>
                        <div className="administration col-1 col-md"/>
                    </div>
                </div>
            </>
        );
    }
}