import React, {useState, useEffect} from "react";

async function registerUser(registerData){
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/signup`, {
        body: JSON.stringify(
            registerData
        ),
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST'
    })
}

export const Registration = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [specialisation, setSpecialisation] = useState([]); // for showing the specialisations
    const [selectedSpecialisation, setSelectedSpecialisation] = useState(""); // to use it in the backend for comparison and set the specialisation for the user
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({
        minValueValidation: false,
        numberValidation: false,
        capitalLetterValidation: false,
        specialCharacterValidation: false,
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/specialisations`)
            .then(response => response.json())
            .then(data => {
                setSpecialisation(data);
            })
            .catch(error => {
                console.error({ error: error.message });
            });
        }, []);

    const validatePassword = (password) => {
        setErrors({
            minValueValidation: password.length >= 8,
            numberValidation: /\d/.test(password),
            capitalLetterValidation: /[A-Z]/.test(password),
            specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
        });
    };

    const handlePasswordSafety = (event) => {
        const checkedPassword = event.target.value;
        setPassword(checkedPassword);
        validatePassword(checkedPassword);
    }

    const handleRegister = async e => {
        e.preventDefault();

        const lowercaseEmail = email.toLowerCase();

        const is_registered = await registerUser({
            name,
            surname,
            specialisation,
            selectedSpecialisation,
            email: lowercaseEmail,
            password
        });

        if (is_registered){
            window.location.href = "/login";
        } else {
            throw new Error("Kann nicht registrieren");
        }
    }

    return(
        <div className="row">
            <div className="registration col-1 col-md-2"><img className="registration-img" src="/modules.gif" alt="modules"/> </div>
            <div className="registration col-10 col-md-4">
                <div className="registration__container">
                    <h1>Registration</h1>
                    <form onSubmit={handleRegister}>
                        <h5>Vorname</h5>
                        <input type="text" onChange={e => setName(e.target.value)} required/>

                        <h5>Nachname</h5>
                        <input type="text" onChange={e => setSurname(e.target.value)} required/>

                        <h5>Ausbildung</h5>
                        <select value={selectedSpecialisation} onChange={e => setSelectedSpecialisation(e.target.value)} required>
                            <option value="">Ausbildung aussuchen</option>
                            {specialisation.map(specialisations => (
                                <option key={specialisations._id} value={specialisations.specialisation_id}>
                                    {specialisations.specialisation}
                                </option>
                            ))}
                        </select>

                        <h5>E-Mail</h5>
                        <input type="email" onChange={e => setEmail(e.target.value)} required/>

                        <h5>Passwort</h5>
                        <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}" onChange={handlePasswordSafety} required/>

                        {Object.entries(errors).map(([key, value]) => (
                            <div key={key}>
                                {value ? (
                                    <>
                                        ✔️
                                    </>
                                ) : (
                                    <>
                                        ❌
                                    </>
                                )}
                                <p>
                                    {key === 'minValueValidation' && 'Passwort muss 8 Zeichen lang sein'}
                                    {key === 'numberValidation' && 'Passwort muss eine Zahl enthalten'}
                                    {key === 'capitalLetterValidation' && 'Passwort muss einen grossen Buchstaben haben'}
                                    {key === 'specialCharacterValidation' && 'Passwort muss ein besonderes Zeichen enthalten'}
                                </p>
                            </div>
                        ))}

                        <br/><br/>

                        <button type="submit">Registrieren</button>
                    </form>
                </div>
            </div>
            <div className="registration col-1 col-md-2"/>
        </div>
    );
}