import React, {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {deleteWithToken, getWithToken, LoginCheck, putWithToken} from "../../Services/DataSettings";

export const ManageRoles = () => {
    const {user} = useParams();
    const isLoggedIn = LoginCheck();
    const navigate = useNavigate();

    const [users, setUsers] = useState([])

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState("");
    const [error, setError] = useState("");

    /**
     * gets all users from the database
     */
    const getUser = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/users/${user}`);

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting users for managing roles"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setUsers(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    /**
     * gets all roles from the database
     */
    const getRoles = async () => {
        try{
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting roles for user"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setRoles(data);
        }catch (e) {
            console.error({error: e.message});
            setError("Da lief etwas falsch beim Holen der Spezialisierungsdaten");
        }
    };

    //loads data once until successful or based on dependency
    useEffect(() => {
        getRoles();
    }, []);
    useEffect(() => {
        getUser();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);

        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            getRoles();
            getUser();
        }else{
            setError("Der Benutzer ist nicht angemeldet");
        }
    }, [isLoggedIn]);

    /**
     * deletes the chosen role in the backend
     * @param roleId - ID of the role
     */
    const deleteRoles = async (roleId) => {
        try {
            const res = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/deleteRole/${users._id}/${roleId}`);

            if (!res.ok) {
                console.error({error: "Cannot delete the role"});
                setError("Rolle kann nicht gelöscht werden");
            }else{
                console.log({message: "Role was deleted"});
                alert("Rolle wurde gelöscht");
                window.location.reload();
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    /**
     * adds the role to the user
     *
     * @param role - role to be added
     * @return {Promise<boolean>} - returns success or failure
     */
    const addRoles = async (role) => {
        try{
            const res = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/addRole/${users._id}`, role);

            if (!res.ok) {
                console.error({error: "Cannot add role"});
                setError("Konnte die Rolle nicht hinzufügen");
            }else{
                const updatedRole = await res.json();
                setUsers(updatedRole);
                return true;
            }
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    /**
     * form handler for adding role
     * @param e - event handler
     */
    const handleAdd = async (e) => {
        e.preventDefault();

        const isAdded = await addRoles({
            roles,
            selectedRoles
        });

        if (isAdded) {
            console.log({message: "Role added"});
            alert("Rolle wurde hinzugefügt");
            window.location.reload();
        } else {
            console.error({error: "User couldn't be edited"});
            setError("Rolle konnte nicht hinzugefügt werden");
        }
    };

    //checks for error and returns HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="role-detail__container">
                    <div className="row">
                        <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif" alt="modules"/></div>
                        <div className="administration col-10 col-md-4">
                            <h1>Rollen Details zum Nutzer {users.name}</h1>
                            <h5>Rollen des Benutzers</h5>
                            {users && users.roles && users.roles.map((role) => (
                                <>
                                    <ul key={role._id}>
                                        <li>
                                            {role.role}
                                            <button className="role-delete" onClick={() => deleteRoles(role._id)}>Löschen</button>
                                        </li>
                                    </ul>
                                </>
                            ))}
                            <div className="adding-users">
                                <h5>Rollen hinzufügen</h5>
                                <form onSubmit={handleAdd}>
                                    <select value={selectedRoles} onChange={(e) => setSelectedRoles(e.target.value)}
                                            required>
                                        <option value="">Rolle auswählen zum Hinzufügen</option>
                                        {roles.map(role => (
                                            <option key={role._id} value={role._id}>
                                                {role.role}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="submit">Speichern</button>
                                    <button className="go-back" onClick={() => navigate('/administration/users')}>Zurück zur Liste</button>
                                </form>
                            </div>
                        </div>
                        <div className="administration col-1 col-md-2"/>
                    </div>
                </div>
            </>
        );
    }
}