import React, {useState, useEffect} from "react";

async function loginUser(credentials) {
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
        });

        const data = await res.json();

        if (data.accessToken) {
            localStorage.setItem('token', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
        }

        return data;
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Login fehlgschlagen");
    }
}

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setError(error);
    }, []);

    const handleSubmit = async e => {
        e.preventDefault(); // does not allow pre entered input / resets to null after pressing the button
        const lowercaseEmail = email.toLowerCase();

        document.querySelector(".login button").innerHTML = "Überprüfung...";

        const isLoggedIn = await loginUser({
            email: lowercaseEmail,
            password
        });

        document.querySelector(".login button").innerHTML = "Einloggen";

        if (isLoggedIn) {
            window.location.href = '/';
        } else {
            setError('Falsches E-Mail oder Passwort angegeben');
        }
    }

    return (
        <div className="row">
            <div className="login col-1 col-md-2"><img className="login-img" src="/modules.gif" alt="modules"/> </div>
            <div className="login col-10 col-md-4">
                <div className="login__container">
                    <h1>Willkommen</h1>
                    <form onSubmit={handleSubmit}>
                        <h5>E-Mail</h5>
                        <input type="email" onChange={e => setEmail(e.target.value)} required/>

                        <h5>Passwort</h5>
                        <input type="password" onChange={e => setPassword(e.target.value)} required/>

                        {error && <div className="error">{error}</div>}

                        <br/><br/>

                        <button type="submit" className="login__signInButton">Einloggen</button>
                    </form>
            </div>

            </div>
            <div className="login col-1 col-md-2"/>
        </div>
    )
}