import {LoginCheck, useFetchUserData, useGetUserData} from "../Services/DataSettings";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

export const TimetrackerSubjectList = () => {
    const {data} = useGetUserData();
    const {userId} = useFetchUserData();

    const navigate = useNavigate();

    const [subjects, setSubjects] = useState([]);
    const [modules, setModules] = useState([]);
    const [error, setError] = useState("");

    const loadModules = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/modules`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!data || (data.code < 200 || data.code >= 300)){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on timetracker page getting timetrackers for list of subjects"})
                setError("Etwas ist schief gelaufen beim Holen der Daten");
                window.location.href = "/500";
            }

            setModules(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message)
        }
    }

    const loadSubjects = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subjects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = await res.json();

            if (!data || (data.code < 200 || data.code >= 300)){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on timetracker page getting timetrackers for list of subjects"})
                setError("Etwas ist schief gelaufen beim Holen der Daten");
                window.location.href = "/500";
            }

            setSubjects(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    useEffect(() => {
        if (LoginCheck()) {
            loadModules();
            loadSubjects();
        } else {
            setError('Der Benutzer ist nicht eingeloggt');
            window.location.href = "/login";
            localStorage.removeItem('token');
        }
    }, [userId]);

    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);

    if (error) {
        return <>Fehler: {error}</>
    }else{
        return (
            <div className="timetrackerhome__container">
                <div className="row">
                    <div className="timetrackerhome col-1 col-md-2"><img className="login-img" src="/modules.gif"
                                                                         alt="modules"/>
                    </div>
                    <div className="timetrackerhome col-10 col-md-4">
                        <h1>Willkommen {data.name} - Zeiterfassung</h1>
                        <h5>Wähle einen Fach aus</h5>
                        {subjects.map((subject, index) => (
                            <div key={index}>
                                <button onClick={() => navigate(`/timetracker/${subject._id}`)}>
                                    {subject.subject}
                                </button>
                            </div>
                        ))}
                        {modules.map((module, index) => (
                            <div key={index}>
                                <button onClick={() => navigate(`/timetracker/${module._id}`)}>
                                    {module.number} - {module.name}
                                </button>
                            </div>
                        ))}
                        <button onClick={() => navigate('/timetracker/own_theme')}>
                            Eigenes Thema
                        </button>
                    </div>
                    <div className="timetrackerhome col-1 col-md-2"/>
                </div>
            </div>
        );
    }
}