import dayjs from 'dayjs';

export const TrackerList = ({timetracker}) => {
    return (
        timetracker.timeEntries.map(entries => {
            return (
                <div className="row">
                    {entries.text !== null && entries.duration > 0 ? (
                        <div className="col-12 tracker-container">
                            <div className="tracker-date">
                                {dayjs(entries.createdAt).format("DD.MM.YY")}
                            </div>
                            <div className="tracker-title">
                                {timetracker && timetracker.subject_id && timetracker.subject_id.subject || timetracker && timetracker.module_id && timetracker.module_id.number + " - " + timetracker.module_id.name || "Eigenes Thema"}
                            </div>
                            <div className="tracker-duration">
                                {entries.duration}
                            </div>
                            <div className="tracker-text">
                                {entries.text}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        })
    );
}
