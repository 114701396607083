import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {LoginCheck, postWithToken} from "../../Services/DataSettings";

async function creatHoliday(holiday) {
    const res = await postWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/createHoliday`, holiday);

    return res.ok;
}
export const Create = () => {
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [type, setType] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const isLoggedIn = LoginCheck();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isCreated = await creatHoliday({
            from,
            to,
            type,
        });

        if (!isCreated) {
            console.error({error: "There was an error while saving holiday"});
            setError("Da lief was beim Speichern der Ferien schief.");
            return;
        }

        console.log({message: "Successfully saved holiday"});
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    }

    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, []);
    useEffect(() => {
        renderCreate();
    }, [from, to, type]);

    const renderCreate = () => {
        if (error) {
            return (
                <>
                    Fehler: {error}
                </>
            );
        }

        if (!isLoggedIn) {
            return (
                <>
                    Nicht eingeloggt
                    <a href="/login">Login</a>
                </>
            );
        }

        return (
            <>
                <div className="detail-holiday__container">
                    <div className="row">
                        <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif" alt="modules"/></div>
                        <div className="administration col-10 col-md-8">
                            <h1>Schulferien erstellen</h1>
                            <form onSubmit={handleSubmit}>
                                <h5>Schulferien Start</h5>
                                <input type="date" onChange={(e) => setFrom(e.target.value)} required/>

                                <h5>Schulferien Ende</h5>
                                <input type="date" onChange={(e) => setTo(e.target.value)} required/>

                                <h5>Art der Ferien</h5>
                                <input type="text" onChange={(e) => setType(e.target.value)}/>

                                <button type="submit">Speichern</button>
                                <button className="go-back" onClick={() => navigate('/administration/holidays')}>Zurück zur Liste</button>
                            </form>
                        </div>
                        <div className="administration col-1 col-md-2"/>
                    </div>
                </div>
                {showSuccessMessage && (
                    <div className="success-message">
                        Erfolgreich gespeichert
                    </div>
                )}
            </>
        )
    }

    return renderCreate();
}