import {Workdays} from "./Workdays";
import {useDroppable} from "@dnd-kit/core";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, {useState, useEffect, useRef} from "react";
import {holidays} from "./Holiday";
import {PrepareModulesList} from "../PrepareModulesList";

export function Calendar({modulecards, state, col, selectModule, comparator, workdays, holidays}) {
    const [height, setHeight] = useState(0);
    const [rowHeight, setRowHeight] = useState(0);
    const [modColWidth, setModColWidth] = useState(0);
    const refRow = useRef(null);
    const refModCol = useRef(null);
    const ref = useRef(null);
    const {setNodeRef} = useDroppable({
        id: `${state}`,
    });

    dayjs.extend(isBetween);

    class RowInfo {
        constructor(height, startdate, enddate) {
            dayjs.extend(isBetween);
            this.height = height;
            this.startdate = startdate;
            this.enddate = enddate;
            this.isBetween = (date) => {
                return dayjs(date).isBetween(this.startdate, this.enddate, "d", "[]");
            };
        }
    }

    const printCard = (card) => {
        return (
            <div
                key={"mw-" + card.module_id._id}
                className="modulwrapper"
                style={{top: card.top, height: card.height, width: modColWidth}}
            >
                <PrepareModulesList
                    modulecard={card}
                    key={card.module_id._id}
                    selectModule={selectModule}
                />
            </div>
        );
    };

    const rowInfos = [];

    if (!comparator) {
        comparator = (a, b) => a.module_id.number - b.module_id.number;
    }

    const getRows = () => {
        let rows = [];
        let currentMonday = dayjs().startOf('week').add(1, "d");

        for (let i = 0; i < 42; i++) {
            const date = dayjs(currentMonday).add(7 * (i - 6), "d", "[]");
            const startdate = date;
            const enddate = date.add(7, "d", "[]");

            let isHoliday = holidays.find((holidayEntry) => {
                return dayjs(date).isBetween(holidayEntry.from, holidayEntry.to, "d", "[]")
            }) !== undefined;

            let info = new RowInfo(
                rowHeight + rowHeight * i,
                startdate,
                enddate
            );
            rowInfos.push(info);

            let currentWorkdays = workdays.filter((workday) => {
                return info.isBetween(workday.date);
            });
            rows.push(
                <tr key={i} ref={refRow}>
                    <td>{date.format("DD.MM")}</td>
                    <td ref={refModCol}></td>
                    <td className={isHoliday ? "holiday wd" : "wd"}>
                        <Workdays startdate={startdate} workdays={currentWorkdays}/>
                    </td>
                </tr>
            );
        }
        return rows;
    };

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setRowHeight(refRow.current.clientHeight);
        setModColWidth(refModCol.current.clientWidth);
    }, []);

    const printCards = () => {
        const c = calculateCards();
        return c.map((card) => printCard(card));
    }

    const calculateCards = () => {
        return modulecards
            .filter(function (modulecard) {
                return modulecard.state_id.state === state;
            })
            .sort((a, b) => comparator(a, b))
            .map((modulecard) => {
                modulecard.top = "-50px";
                rowInfos.map((rowInfo) => {
                    if (modulecard.startdate && rowInfo.isBetween(modulecard.startdate)) {
                         return modulecard.top = rowInfo.height;
                    } else if (modulecard.enddate && rowInfo.isBetween(modulecard.enddate)) {
                        return modulecard.height = rowHeight + rowInfo.height - modulecard.top;
                    }else {
                        return modulecard;
                    }
                });
                return modulecard;
            });
    };

    return (
        <div ref={setNodeRef} className={`state ${state} ${col}`}>
            <h2>
                Masterplan (
                {
                    modulecards.filter(function (module) {
                        return module.state_id.state === state;
                    }).length
                }
                )
            </h2>
            <div className="masterplanwrapper">
                <table ref={ref} className="masterplan">
                    <tbody>
                    <tr>
                        <th width="35px">Datum</th>
                        <th className="modulecol">Module</th>
                        <th className="wd">Schultage</th>
                    </tr>
                    {getRows().map((row) => {
                        return row;
                    })}
                    </tbody>
                </table>
                {printCards()}
            </div>
        </div>
    );
}