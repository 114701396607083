import {useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {getWithToken, LoginCheck} from "../../Services/DataSettings";

export const Details = () => {
    const navigate = useNavigate();
    const [holidays, setHolidays] = useState([]);
    const [error, setError] = useState("");
    const isLoggedIn = LoginCheck();

    const loadHolidays = async () => {
        try {
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/holidays`);
            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting users for a list"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setHolidays(data);
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            loadHolidays();
        } else {
            setError("Benutzer ist nicht angemeldet.");
        }
    }, [isLoggedIn]);
    useEffect(() => {
        if (error){
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        renderDetails();
    }, []);

    const renderDetails = () => {
        if (!holidays) {
            return (
                <>
                    <div className="loading"></div>
                </>
            );
        }

        return holidays.map((holiday) => {
            return (
                <>
                    <div className="administration col-1 col-md-2"></div>
                    <div key={holiday._id} className="detail-holiday col-10 col-md-8">
                        <div className="holiday-type">
                            {holiday.type}
                        </div>
                        <div className="holiday-timespan">
                            {holiday.from} - {holiday.to}
                        </div>
                        <div className="detail-linkto">
                            <button onClick={() => navigate(`/administration/holidays/edit/${holiday._id}`)}>Bearbeiten</button>
                        </div>
                    </div>
                    <div className="administration col-1 col-md-2"></div>
                </>
            )
        })
    };

    if (error) {
        return <>Fehler: {error}</>
    } else {
        return (
            <>
                <div className="detail-holiday_container">
                    <div className="row">
                        <div className="col-1 col-md-2"></div>
                        <h2 className="list-title col-10 col-md-8">Schulferien</h2>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-1 col-md-2"></div>
                        <button className="col-5 col-md-4 adding-button" onClick={() => navigate('/administration/holidays/create')}>Ferien hinzufügen</button>
                        <button className="col-5 col-md-4 go-back-to-main" onClick={() => navigate('/administration')}>Zurück zum Hauptbildschirm</button>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    <div className="row">
                        {renderDetails()}
                    </div>
                </div>
            </>
        );
    }
}