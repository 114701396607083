import './css/App.css';
import './css/login.css';
import './css/nav.css';
import './css/registration.css';
import './css/changepassword.css';
import './css/dependencies.css';
import './css/workday.css';
import './css/table.css';
import './css/dates.css';
import './css/planner.css';
import './css/timetracker.css';
import './css/student.css';
import './css/moderator.css';
import './css/admin.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Login} from "./Components/Authentication/Login";
import {Registration} from "./Components/Authentication/Registration";
import {Layout} from "./Components/Layout/Layout";
import {NotFound} from "./Components/Layout/NotFound";
import {ChangePassword} from "./Components/Admin/Users/ChangePassword";
import {LoginCheck, CheckModerator, CheckAdmin} from "./Components/Services/DataSettings";
import {Modulplanner} from "./Components/Planner/Modulplanner";
import {Info} from "./Components/Info/Info";
import {Summary} from "./Components/Teacher/Summary";
import {WebsiteAdministration} from "./Components/Admin/Website-Administration";
import {UsersList} from "./Components/Admin/Users/UsersList";
import {Modulelist} from "./Components/Admin/Modules/Modulelist";
import {DetailModules} from "./Components/Admin/Modules/DetailModules";
import {CreateModules} from "./Components/Admin/Modules/CreateModules";
import {CreateUsers} from "./Components/Admin/Users/CreateUsers";
import {DetailsUsers} from "./Components/Admin/Users/DetailsUsers";
import {ManageRoles} from "./Components/Admin/Users/ManageRoles";
import {InternalServerError} from "./Components/Layout/InternalServerError";
import {Forbidden} from "./Components/Layout/Forbidden";
import {Unauthorized} from "./Components/Layout/Unauthorized";
import {TimetrackerSubjectList} from "./Components/NewTimetracker/TimetrackerSubjectList";
import {TimetrackerEntries} from "./Components/NewTimetracker/TimetrackerEntries";
import {Details} from "./Components/Admin/Holiday/Details";
import {Create} from "./Components/Admin/Holiday/Create";
import {Edit} from "./Components/Admin/Holiday/Edit";


const getDefault = () => {
    let isModerator = CheckModerator();
    let isAdmin = CheckAdmin();
    let isLoggedIn = LoginCheck();

    if (isLoggedIn){
        if (isAdmin){
            return <WebsiteAdministration/>;
        }

        if (isModerator){
            return <Summary/>;
        }

        return <Modulplanner/>;
    }else{
        return <Login/>;
    }
};

function App() {
  return (
      <>


        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout/>}>
                  <Route index element={getDefault()}/>
                  <Route path="login" element={<Login/>}/>
                  <Route path="register" element={<Registration/>}/>
                  <Route path="info" element={<Info/>}/>
                  <Route path="planner" element={<Modulplanner/>}/>
                  <Route path="summary" element={<Summary/>}/>
                  <Route path="administration" element={<WebsiteAdministration/>}/>
                  <Route path="administration/users" element={<UsersList/>}/>
                  <Route path="administration/users/create" element={<CreateUsers/>}/>
                  <Route path="administration/users/detail/:user" element={<DetailsUsers/>}/>
                  <Route path="administration/users/detail/:user/change-password" element={<ChangePassword/>}/>
                  <Route path="administration/users/detail/:user/manage-roles" element={<ManageRoles/>}/>
                  <Route path="administration/modules" element={<Modulelist/>}/>
                  <Route path="administration/modules/create" element={<CreateModules/>}/>
                  <Route path="administration/modules/detail/:module" element={<DetailModules/>}/>
                  <Route path="administration/holidays" element={<Details/>}/>
                  <Route path="administration/holidays/create" element={<Create/>}/>
                  <Route path="administration/holidays/edit/:holiday" element={<Edit/>}/>
                  <Route path="timetracker" element={<TimetrackerSubjectList/>}/>
                  <Route path="timetracker/:subject" element={<TimetrackerEntries/>}/>
                  <Route path="500" element={<InternalServerError/>}/>
                  <Route path="403" element={<Forbidden/>}/>
                  <Route path="401" element={<Unauthorized/>}/>
                  <Route path="test" element={<Details/>}/>
                  <Route path="*" element={<NotFound/>}/>
              </Route>
          </Routes>
        </BrowserRouter>
      </>
  );
}

export default App;

