import React, { useState, useEffect } from "react";
import {ModulesList} from "./ModulesList";
import {TrackerList} from "./TrackerList";
import {fetchWithToken, getWithToken, refreshToken} from "../Services/DataSettings";

export const Summary = () => {
    const [modules, setModules] = useState([]);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAllStudentsMods = async () => {
            try {
                const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/mod/students`);

                const data = await res.json();

                if (!res.ok){
                    console.log({error: "Couldn't fetch students for teachers"});
                    setError('Fehler beim Holen der Daten der Schüler für Lehrpersonen')
                }

                if (data.code === 403){
                    console.error({error: `Authorization problem.`})
                    setError("Nicht autorisiert für diese Seite");
                    window.location.href = "/403";
                }

                if (data.code === 500){
                    console.error({error: "Server Error on teacher page getting students"})
                    setError("Da lief was schief auf unserem Ende");
                    window.location.href = "/500";
                }

                setStudents(data);
            }catch (e) {
                console.error({ error: e.message });
                setError(e.message);
            }
        };

        fetchAllStudentsMods();
    }, []);

    useEffect(() => {
        const fetchModulesMods = async () => {
            try {
                const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/mod/modules`);

                const data = await res.json();

                if (!res.ok){
                    console.log({error: "Couldn't fetch students for teachers"});
                    setError('Fehler beim Holen der Daten der Schüler für Lehrpersonen')
                }

                if (data.code === 401){
                    console.error({error: `Authentication problem.`})
                    setError("Nicht authentifiziert für diese Seite");
                    window.location.href = "/401";
                }

                if (data.code === 403){
                    console.error({error: `Authorization problem.`})
                    setError("Nicht autorisiert für diese Seite");
                    window.location.href = "/403";
                }

                if (data.code === 500){
                    console.error({error: "Server Error on teacher page getting modulecards"})
                    setError("Da lief was schief auf unserem Ende");
                    window.location.href = "/500";
                }

                setModules(data);
            }catch (e) {
                console.error({ error: e.message });
                setError(e.message);
            }
        };

        fetchModulesMods();
    }, []);

    useEffect(() => {
        if (error){
            alert('Ein Fehler ist aufgetreten. Bitte melden sie sich beim IT-Support oder beim Lehrer mit folgendem Fehler:' + error);
        }
    }, [error]);
    useEffect(() => {
        renderStudents();
    }, [students]);
    useEffect(() => {
        renderModules();
    }, [modules]);

    const renderModules = () => {
        if (!modules) {
            return <div className="loading"> </div>;
        }

        const groupedModules = {};

        // this just makes so the name is only shown once and not many times
        modules.forEach(student => {
            const fullName = `${student.user_id.name} ${student.user_id.surname}`;

            if (!groupedModules[fullName]) {
                groupedModules[fullName] = [];
            }
            groupedModules[fullName].push(student);
        });

        // this filters from startdates and enddates
        return Object.keys(groupedModules).map((fullName) => {
            const modulesForUser = groupedModules[fullName].filter(module => module.startdate && module.enddate);

            if (modulesForUser.length === 0){
                return null;
            }

            return (
                <div key={fullName}>
                    <div className="module-studentname">
                        {fullName}
                    </div>
                    {groupedModules[fullName].map(student => (
                        <ModulesList key={student._id} modules={student}/>
                    ))}
                </div>
            )
        });
    };

    const renderStudents = () => {
        if (!students) {
            return <div className="loading"></div>;
        }

        return students.map((student) => {
            return (
                <>
                    {" "}
                    <div key={student._id} className="col-12">
                        {" "}
                        {student.name} {student.surname}{" "}
                        {" "}

                        {/* Kaleneder URL (provisorisch) */}
                    </div>
                </>
            );
        });
    };

    if (error){
        return <>Fehler: {error}</>;
    }else{
        return (
            <div className="container students">
                <div className="row">
                    <h2>Zu betreuende Module</h2>
                    {renderModules()}
                </div>
                <br/><br/>
                <br/><br/>
                <div className="row">
                    <h2>Zu betreuende Lernende</h2>
                    {renderStudents()}
                </div>
            </div>
        );
    }
};