// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#search {
    width: 300px;
    border-radius: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border: 1px solid #932432;
}

.modal-body a {
    margin-left: 5px;
}

.students {
    margin-top: 60px;
}

.studentcard {
    color: #283747;
    background-color: #93243244;
    padding: 8px;
    margin: 3px;
    font-size: 0.9em;
}

.studentcard h3 {
    font-weight: bold;
    font-size: 1.1em;
    padding: 0;
    margin: 0;
}

.studentcard p {
    padding: 0;
    margin: 0;
}

`, "",{"version":3,"sources":["webpack://./src/css/student.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;IACzC,0BAA0B;IAC1B,kCAAkC;IAClC,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":["#search {\r\n    width: 300px;\r\n    border-radius: 0px;\r\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\r\n    backdrop-filter: blur(0px);\r\n    -webkit-backdrop-filter: blur(0px);\r\n    border: 1px solid #932432;\r\n}\r\n\r\n.modal-body a {\r\n    margin-left: 5px;\r\n}\r\n\r\n.students {\r\n    margin-top: 60px;\r\n}\r\n\r\n.studentcard {\r\n    color: #283747;\r\n    background-color: #93243244;\r\n    padding: 8px;\r\n    margin: 3px;\r\n    font-size: 0.9em;\r\n}\r\n\r\n.studentcard h3 {\r\n    font-weight: bold;\r\n    font-size: 1.1em;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.studentcard p {\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
