import {Menu} from "./Menu";

export const Navigation = () => {
    return (
        <div className="navigation">
            <nav className="navbar top">
                <div className="container-fluid">
                    <span className="bbb_logo"><a href="/"><img src="/bbb_logo.jpg" alt="logo_bbb"></img></a></span>
                    <span className="logotitle"><a href="/"><img src="/logo2.png" alt="logo"></img>Modulplanner</a></span> {/* logo */}
                    <Menu/> {/* menu class */}
                </div>
            </nav>
        </div>
    )
}