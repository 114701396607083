import React, {useState, useEffect} from "react";
import {getWithToken, LoginCheck} from "../../Services/DataSettings";
import {useNavigate} from "react-router-dom";
export const UsersList = () => {
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [error, setError] = useState("");

    const isLoggedIn = LoginCheck();

    /**
     * gets all users from database
     */
    const loadAllUsers = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/users`);
            const data = await res.json();

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting users for a list"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setAllUsers(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    //loads all data once until successful or based on dependency
    useEffect(() => {
        loadAllUsers();
    }, [allUsers]);
    useEffect(() => {
        listOfUsers();
    }, []);
    useEffect(() => {
        if (isLoggedIn){
            loadAllUsers();
        }else{
            setError("Benutzer ist nicht angemeldet.")
        }
    }, []);
    useEffect(() => {
        if (error){
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);

    /**
     * It gives out all the details from every user or a loading screen
     * @return {JSX.Element|unknown[]} - returns a list of users mapped through the array
     */
    const listOfUsers = () => {
        if (!allUsers) {
            return (
                <>
                    <div className="loading"></div>
                </>
            );
        }

        return allUsers.map((users) => {
            return (
                <>
                    <div key={users._id} className="user-list col-12">
                        <div className="user-fullname">
                            {users.name} {users.surname}
                        </div>
                        <div className="user-mail">
                            {users.email}
                        </div>
                        <div className="user-specialisation">
                            {users.specialisation_id.specialisation}
                        </div>
                        <div className="user-roles">
                            {users.roles.map(role => (
                                <ul>
                                    <li>
                                        {role.role}
                                    </li>
                                </ul>
                            ))}
                        </div>
                        <div className="user-details">
                            <a href={`/administration/users/detail/${users._id}`}>Details</a>
                        </div>
                    </div>
                </>
            );
        });
    };

    //checks for error and gives out HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="administrationUsers__container">
                    <h2 className="list-title">Nutzerliste</h2>
                    <div className="row">
                        <button className="col-3 adding-button" onClick={() => navigate('/administration/users/create')}>Nutzer hinzufügen</button>
                        <button className="col-3 go-back-to-main" onClick={() => navigate('/administration')}>Zurück zur Liste</button>
                    </div>
                    <div className="row">
                        {listOfUsers()}
                    </div>
                </div>
            </>
        );
    }
}