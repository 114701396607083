// every holiday during the year
export const holidays = [
    // 2022/2023
    {from: '2023-02-06', to: '2023-02-19'},
    {from: '2023-04-10', to: '2023-04-23'},
    {from: '2023-07-10', to: '2023-08-13'},

    // 2023/2024
    {from: '2023-10-02', to: '2023-10-15'},
    {from: '2023-12-25', to: '2024-01-07'},
    {from: '2024-02-05', to: '2024-02-18'},
    {from: '2024-04-08', to: '2024-04-21'},
    {from: '2024-07-08', to: '2024-08-11'},
];