// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workdays {
    border: none;
}

.workday {
    background-color: #f3f3f300;
    border: none;
    width: 25px;
}

button.workday-set {
    background-color: #283747;
    color: #f9f9f8;
    border: none;
    width: 25px;
}

.workdays .workday {
    border: none;
}

button.saving {
    background-color: #5a9324;
}`, "",{"version":3,"sources":["webpack://./src/css/workday.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".workdays {\r\n    border: none;\r\n}\r\n\r\n.workday {\r\n    background-color: #f3f3f300;\r\n    border: none;\r\n    width: 25px;\r\n}\r\n\r\nbutton.workday-set {\r\n    background-color: #283747;\r\n    color: #f9f9f8;\r\n    border: none;\r\n    width: 25px;\r\n}\r\n\r\n.workdays .workday {\r\n    border: none;\r\n}\r\n\r\nbutton.saving {\r\n    background-color: #5a9324;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
