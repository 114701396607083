// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation{
    background-color: #f0eded83;
    color: #191033;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar {
    font-size: 1em;
    padding: 0;
}

.navbar a{
    color: #191033;
}

.navbar a:hover {
    color: #932432;
}

.loading {
    font-family: "Bungee", cursive;
    color: #191033;
    font-size: 8em;
    margin: 20vh;
    animation: blink 1s infinite;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    padding-top: 150px;
    min-height: 150px;
    min-width: 150px;
    left: 20vw;
    position: absolute;
    top: 10vh;
}

.logotitle a{
    font-family: "Bungee", cursive;
    color: #191033;
    font-size: 2em;
    text-decoration: none;
}

.logotitle a img {
    height: 25px;
    margin-right: 5px;
    animation: rotate 20s infinite;
    animation-timing-function: linear;
    margin-top: -7px;
}

.bbb_logo a img {
    height: 50px;
    margin-right: 5px;
    margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/css/nav.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;IACd,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,cAAc;IACd,cAAc;IACd,YAAY;IACZ,4BAA4B;IAC5B,yDAA8C;IAC9C,4BAA4B;IAC5B,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,8BAA8B;IAC9B,cAAc;IACd,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".navigation{\r\n    background-color: #f0eded83;\r\n    color: #191033;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.navbar {\r\n    font-size: 1em;\r\n    padding: 0;\r\n}\r\n\r\n.navbar a{\r\n    color: #191033;\r\n}\r\n\r\n.navbar a:hover {\r\n    color: #932432;\r\n}\r\n\r\n.loading {\r\n    font-family: \"Bungee\", cursive;\r\n    color: #191033;\r\n    font-size: 8em;\r\n    margin: 20vh;\r\n    animation: blink 1s infinite;\r\n    background-image: url(\"../../public/logo.svg\");\r\n    background-repeat: no-repeat;\r\n    padding-top: 150px;\r\n    min-height: 150px;\r\n    min-width: 150px;\r\n    left: 20vw;\r\n    position: absolute;\r\n    top: 10vh;\r\n}\r\n\r\n.logotitle a{\r\n    font-family: \"Bungee\", cursive;\r\n    color: #191033;\r\n    font-size: 2em;\r\n    text-decoration: none;\r\n}\r\n\r\n.logotitle a img {\r\n    height: 25px;\r\n    margin-right: 5px;\r\n    animation: rotate 20s infinite;\r\n    animation-timing-function: linear;\r\n    margin-top: -7px;\r\n}\r\n\r\n.bbb_logo a img {\r\n    height: 50px;\r\n    margin-right: 5px;\r\n    margin-top: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
