import {jwtDecode} from "jwt-decode";
import {useEffect, useState} from "react";
// verifiedUsers fetches the data from a user specific after he has logged in
// use was used to make custom tags for React (only way to do a fetch and return and useEffect, it accepts custom hooks or inside a React Component)
export const useFetchUserData = () => {
    const [userId, setUserId] = useState([]);
    const [specialisationsId, setSpecialisationsId] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/verifiedUsers`);

                const data = await res.json();

                if (!res.ok){
                    console.error({error: "Something went wrong while fetching data"});
                }

                if (data.code === 403){
                    console.error({error: `Authorization problem.`})
                    window.location.href = "/403";
                }

                if (data.code === 500){
                    console.error({error: "Server Error on admin page create modules"})
                    window.location.href = "/500";
                }

                setUserId(data._id);
                setSpecialisationsId(data.specialisation_id);
            }catch (e) {
                console.error({error: e.message});
            }
        };
        fetchData();
    }, []);
    return { userId, specialisationsId };
};

export const useGetSpecialisationsAfterLogin = () => {
    const [specialisations, setSpecialisations] = useState([]);
    const { specialisationsId } = useFetchUserData();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/specialisations/${specialisationsId}`);

                const data = await res.json();

                if (!res.ok){
                    console.error({error: "Something went wrong while fetching data"});
                }

                if (data.code === 403){
                    console.error({error: `Authorization problem.`})
                    window.location.href = "/403";
                }

                if (data.code === 500){
                    console.error({error: "Server Error on admin page create modules"})
                    window.location.href = "/500";
                }

                setSpecialisations(data.specialisation);
            }catch (e) {
                console.error({error: e.message});
            }
        };

        fetchData();
    }, [specialisationsId]);
    return { specialisations };
}

export const useGetUserData = () => {
    const [data, setData] = useState([]);
    const { userId } = useFetchUserData();

    useEffect(() => {
        const fetchData = async () => {
            try{
                const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`)

                const dataBackend = await res.json();

                if (!res.ok){
                    console.error({error: "Something went wrong while fetching data"});
                }

                if (dataBackend.code === 403){
                    console.error({error: `Authorization problem.`})
                    window.location.href = "/403";
                }

                if (dataBackend.code === 500){
                    console.error({error: "Server Error on admin page create modules"})
                    window.location.href = "/500";
                }

                setData(dataBackend);
            }catch (e) {
                console.error({error: e.message});
            }
        };

        fetchData();
    }, [userId]);
    return { data };
}

export const LoginCheck = () => {
    try {
        return (
            localStorage.getItem("token") !== null &&
            jwtDecode(localStorage.getItem("token")).exp > Date.now() / 1000 ||
            localStorage.getItem("refreshToken") !== null &&
            jwtDecode(localStorage.getItem("refreshToken")).exp > Date.now() / 1000
        );
    } catch (e) {
        console.error('Error:', e);
        return false;
    }
};

export const CheckModerator = () => {
    const {data} = useGetUserData();

    const isModerator = data.roles && data.roles.some(role => role.role === 'moderator' && role.role !== 'admin'); // checks for the role moderator and if it exists in that specific array
    return LoginCheck() && isModerator;
}

//done during the IPA, the rest was made before
export const CheckAdmin = () => {
    const {data} = useGetUserData();

    const isAdmin = data.roles && data.roles.some(role => role.role === 'admin' && role.role !== 'moderator'); // check for the role admin in an array
    return LoginCheck() && isAdmin;
}

export const Logout = () => {
    localStorage.clear();
};

export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
            console.error({error: "Token not found"});
        }

        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/refreshToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });

        const data = await res.json();

        if (!data.accessToken) {
            console.error({error: "Failed to refresh token"});
        } else {
            localStorage.setItem('token', data.accessToken);
            return data.accessToken;
        }
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error refresh token");
    }
}

export const fetchWithToken = async (url, options = {}) => {
    try {
        let token = localStorage.getItem('token');

        if (!token) {
            throw new Error('No token found');
        }

        options.headers = {
            ...options.headers,
            'x-access-token': token,
            'Content-Type': 'application/json',
        };

        let res = await fetch(url, options);

        if (res.status === 401) {
            const newToken = await refreshToken();
            if (newToken) {
                options.headers['x-access-token'] = newToken;
                res = await fetch(url, options);
            } else {
                console.error({error: 'Both access and refresh tokens are invalid. Redirecting to login.'});
                alert('Beide Tokens sind ungültig. Sie sind nicht eingeloggt. Bitte loggen sie sich ein');
                window.location.href = '/login';
                localStorage.clear()
            }
        }

        return res;
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error while fetching with token");
    }
}

export const getWithToken = async (url, options = {}) => {
    options.method = 'GET';

    try {
        return await fetchWithToken(url, options);
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error while making GET request with token");
    }
}

export const putWithToken = async (url, data, options = {}) => {
    options.method = 'PUT';
    options.body = JSON.stringify(data);

    try {
        return await fetchWithToken(url, options);
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error while making PUT request with token");
    }
}

export const deleteWithToken = async (url, options = {}) => {
    options.method = 'DELETE';

    try {
        return await fetchWithToken(url, options)
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error while making DELETE request with token");
    }
}

export const postWithToken = async (url, data, options = {}) => {
    options.method = 'POST';
    options.body = JSON.stringify(data);

    try {
        return await fetchWithToken(url, options);
    } catch (e) {
        console.error({error: e.message});
        throw new Error("Error while making POST request with token");
    }
}