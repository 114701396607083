import {
    CheckAdmin,
    CheckModerator,
    LoginCheck,
    useGetSpecialisationsAfterLogin,
    useGetUserData
} from "../Services/DataSettings";

export const Info = () => {
    const { data } = useGetUserData();
    const { specialisations } = useGetSpecialisationsAfterLogin();
    const isModerator = CheckModerator();
    const isAdmin = CheckAdmin();
    const printSpecialisationInfo = () => {
        if (isModerator || isAdmin){
            return (
                <>
                    {printAppInfo()}
                    {printMedInfo()}
                </>
            );
        }

        if (specialisations === "Applikationsentwickler"){
            return printAppInfo();
        }else if (specialisations === "Mediamatiker"){
            return printMedInfo();
        }

        return null;
    };

    const printAppInfo = () => {
        return(
            <div className="col-6">
                <h2>Übersicht Module Applikationsentwicklung</h2>
                <p>
                    Gesamte Ausbildung: 31 Module <br/> (28 Pflicht-Module und 3 von den 12
                    Wahl-Pflicht-Modulen)
                </p>
                <p>Richtwert pro Modul: 40 Lektionen = 5 Tage zu 8 Lektionen.</p>
                <p>
                    Es gibt bei Lernvolution keinen Unterschied zwischen ÜK und Berufsmodulen
                </p>
                <p>Berufskunde über 4 Lehrjahre insgesamt: 31*5 = 155 Tage</p>
                <p>Vorschlag nach Bildungsverordnung: </p>
                <ul>
                    <li>1. Lehrjahr: 11 Module ={">"} 55 Tage</li>
                    <li>2. Lehrjahr: 11 Module ={">"} 55 Tage</li>
                    <li>3. Lehrjahr: 5 Module ={">"} 25 Tage</li>
                    <li>4. Lehrjahr: 4 Module ={">"} 20 Tage</li>
                </ul>
            </div>
        );
    };

    const printMedInfo = () => {
        return (
            <div className="col-6">
                <h2>Übersicht Module Mediamatik</h2>

                <a
                    target="_blank"
                    href="https://bbbaden.sharepoint.com/sites/LernvolutionBBB/_layouts/15/Doc.aspx?sourcedoc={4012fb2b-4532-4128-9420-94de168947b1}&action=edit&wd=target%28Lernvolution.one%7Cbcb2e40e-b430-463f-b3c0-ab373cce0e48%2FMediamatik%20mit%20BM%20%C3%9Cbersicht%20Module%20und%20Schultage%7C3a171f5b-b87f-4075-b6cd-0355712aa48e%2F%29&wdorigin=703"
                    rel="noreferrer"
                >
                    Erklärung auf OneNote für die Lernenden mit BM
                </a>
                <br/>
                <a
                    target="_blank"
                    href="https://bbbaden.sharepoint.com/sites/LernvolutionBBB/_layouts/15/Doc.aspx?sourcedoc={4012fb2b-4532-4128-9420-94de168947b1}&action=edit&wd=target%28Lernvolution.one%7Cbcb2e40e-b430-463f-b3c0-ab373cce0e48%2FMediamatik%20ohne%20BM%20%C3%9Cbersicht%20Module%20und%20Schultage%7Cb7e93c50-46b8-4eb8-a001-84b2c863b893%2F%29&wdorigin=703"
                    rel="noreferrer"
                >
                    Erklärung auf OneNote für die Lernenden ohne BM
                </a>
            </div>
        );
    };

    if (!LoginCheck()) window.location.href = "/login"; // if not logged in go back to /login
    return (
        <>
            <div className="container info">
                <br/>
                <br/>
                <br/>
                <h1>Info für {data.name}</h1>

                <div className="row">
                    <div className="col-6">
                        <h2>Dein Online-Kalender</h2>
                        <p>
                            Diesen Link kannst du mit Leuten teilen, die im Betrieb oder in
                            der Schule mit dir zu tun haben. Der Kalender aktualisiert sich
                            automatisch, wenn du im Modulplanner Dinge anpasst. So sind alle
                            immer informiert wenn sich was ändert.
                        </p>
                        <p>V 0.6.1</p>
                        <p><b>Neue Version von der Zeiterfassung ist in Bearbeitung</b></p>
                        <a href="/*">Kalender Link kopieren</a>
                        {/*Kalender Link */}
                    </div>

                    {printSpecialisationInfo()}

                    <div className="col-3">
                        <img src="/logo.svg" alt="logo"></img>
                    </div>
                </div>
            </div>
        </>
    );
};