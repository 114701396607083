import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {fetchWithToken, getWithToken, LoginCheck} from "../../Services/DataSettings";

export const Modulelist = () => {
    const isLoggedIn = LoginCheck();

    const navigate = useNavigate();
    const [allModules, setAllModules] = useState([]);
    const [error, setError] = useState("");

    /**
     * gets all the modules from MongoDB
     * @return {Promise<void>} - gets all the modules from the MongoDB database through an API link
     */
    const loadAllModules = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/modules`);

            const data = await res.json()

            if (!res.ok){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting modules for list"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setAllModules(data);
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    };

    /**
     * loads all the data based on the dependency,
     * if there is no dependency it loads the data once until successful
     */
    useEffect(() => {
        loadAllModules();
    }, [allModules]);
    useEffect(() => {
        listOfModules();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            loadAllModules();
        } else {
            setError("Der Nutzer ist nicht eingeloggt");
        }
    }, [isLoggedIn]);

    /**
     *
     * @return {JSX.Element|unknown[]} - Loading screen or a list of all the modules
     */
    const listOfModules = () => {
        if (!allModules) {
            return (
                <>
                    <div className="loading"></div>
                </>
            );
        }

        return allModules.map((modules) => {
            return (
                <>
                    <div key={modules._id} className="module-list col-12">
                        <div className="modules-title">
                            {modules.number} - {modules.name}
                        </div>
                        <div className="modules-specialisation">
                            {modules.specialisation_id.specialisation}
                        </div>
                        <div className="modules-year">
                            In diesem Lehrjahr zu bearbeiten: {modules.year}. Jahr
                        </div>
                        <div className="modules-actionskills">
                            {modules.actionskills}
                        </div>
                        <div className="modules-skills">
                            {modules.skill}
                        </div>
                        <div className="modules-object">
                            {modules.object}
                        </div>
                        <div className="modules-remarks">
                            {modules.remarks}
                        </div>
                        <div className="modules-dependency">
                            {modules.dependency}
                        </div>
                        <div className="modules-type">
                            {modules.type}
                        </div>
                        <div className="modules-details">
                            <a href={`/administration/modules/detail/${modules._id}`}>Details</a>
                        </div>
                    </div>
                </>
            );
        });
    };

    /**
     * checks for an error
     * @return HTMLBodyElement - returns error or the web page (list of modules)
     */
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="administrationModules__container">
                    <h2 className="list-title">Modulliste</h2>
                    <div className="row">
                        <button className="col-3 adding-button" onClick={() => navigate('/administration/modules/create')}>Modul erstellen</button>
                        <button className="col-3 go-back-to-main" onClick={() => navigate('/administration')}>Zurück zur Hauptseite</button>
                    </div>
                    <div className="row">
                        {listOfModules()}
                    </div>
                </div>
            </>
        );
    }
}