// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open {
    min-height: 100vh;
}

.planned {
    min-height: 100vh;
}

.inprogress {
    min-height: 100vh;
}

.done {
    min-height: 100vh;
}

.Geplant {
    border-right: 1px solid #191033;
    border-left: 1px solid #191033;
}`, "",{"version":3,"sources":["webpack://./src/css/planner.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,+BAA+B;IAC/B,8BAA8B;AAClC","sourcesContent":[".open {\r\n    min-height: 100vh;\r\n}\r\n\r\n.planned {\r\n    min-height: 100vh;\r\n}\r\n\r\n.inprogress {\r\n    min-height: 100vh;\r\n}\r\n\r\n.done {\r\n    min-height: 100vh;\r\n}\r\n\r\n.Geplant {\r\n    border-right: 1px solid #191033;\r\n    border-left: 1px solid #191033;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
