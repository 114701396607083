import {useEffect, useState} from "react";
import {TimeEntry} from "./EntryTime/TimeEntry";
import {LoginCheck, putWithToken} from "../Services/DataSettings";
import {Details} from "./EntryTime/Details";

export const TimetrackerEntries = () => {
    const [error, setError] = useState("");
    const [initiakSubjects, setInitialSubjects] = useState([]);
    const [initialModules, setInitialModules] = useState([]);

    const [plannedtime, setPlannedtime] = useState('');
    const [totaltime, setTotaltime] = useState('');
    const [date, setDate] = useState('')

    const isLoggedIn = LoginCheck();

    const getModules = async () => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/modules`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await res.json();

        if (!res.ok) {
            console.error({error: "Something went wrong while fetching data"});
            setError("Etwas lief schief beim Holen der Daten");
        }

        setInitialModules(data);
    }

    const getSubjects = async () => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subjects`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await res.json();

        if (!res.ok){
            console.error({error: "Something went wrong while fetching data"});
            setError("Etwas lief schief beim Holen der Daten");
        }

        setInitialSubjects(data);
    }

    const createOrEditTimetracker = async (body) => {
        try {
            const res = await putWithToken(`${process.env.REACT_APP_BACKEND_URL}/timetracker/create-and-edit`, body);

            if (!res.ok){
                console.error({error: "Etwas ist während dem Speichern der Daten schief gelaufen."});
                setError("Etwas lief beim Speichern der Daten schief.");
            }
        } catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn){
            getSubjects();
            getModules();
        } else {
            setError("Der Benutzer ist nicht eingeloggt.");
        }
    }, []);
    useEffect(() => {
        renderEntries();
    }, [date, plannedtime, totaltime]);

    const renderEntries = () => {
        if (error) return <>{error}</>
        return (
            <div className="entries__container">
                <div className="mt-2 w-100 h-50 d-flex justify-content-center row">
                    <div className="col-1 col-md-2"></div>
                    <div className="col-5 col-md-4">
                        <div className="container-box">
                            <p className="title-time-entry">Zeiterfassung</p>
                            {/*<div className="datepicker">
                                <Datepicker label="Datum"/>
                            </div>*/}
                            <div className="time-entry">
                                <TimeEntry
                                    saveTracker={createOrEditTimetracker}
                                    subjects={initiakSubjects}
                                    modules={initialModules}
                                    setPlannedtime={setPlannedtime}
                                    setTotaltime={setTotaltime}
                                    setDates={setDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-4">
                        <div className="container-box">
                            <p className="title-time-entry">Arbeitszeit</p>
                            <Details
                                plannedtime={plannedtime}
                                totaltime={totaltime}
                                date={date}
                            />
                        </div>
                    </div>
                    <div className="col-1 col-md-2"></div>
                </div>
            </div>
        );
    }

    return renderEntries();
}