import {CheckAdmin, CheckModerator, LoginCheck, Logout, useGetUserData} from "../Services/DataSettings";

export const Usermenu = () => {
    const isAdmin = CheckAdmin();
    const isModerator = CheckModerator();

    if (isAdmin) {
        return (
            <>
                <a className="btn badge" href="/administration">
                    Website-Administration
                </a> |
                <a className="btn badge" href="/summary">
                    Zusammenfassung Schüleraktion
                </a> |
                <a className="btn badge" href="/planner">
                    Masterpläne
                </a> |
                <a className="btn badge" href="/info">
                    Info
                </a> |
            </>
        );
    }

    if (isModerator) {
        return (
            <>
                <a className="btn badge" href="/summary">
                    Zusammenfassung Schüleraktion
                </a> |
                <a className="btn badge" href="/planner">
                    Masterpläne
                </a> |
                <a className="btn badge" href="/info">
                    Info
                </a> |
            </>
        );
    }

    return (
        <>
            <a className="btn badge" href="/">
                Masterplan
            </a> |
            <a className="btn badge" href="/info">
                Info
            </a> |
            <a className="btn badge" href="/timetracker">
                Zeiterfassung
            </a> |
        </>
    );
};

export function Menu() {
    const {data} = useGetUserData();
    const logout = () => {
        Logout(); // Logout function from DataSettings
        window.location.href = "/login";
    };

    if (LoginCheck()) {
        return (
            <div className="user">
                Hey {data.name} |{" "} {/* gives out name at the beginning */}

                <Usermenu/> {/* the links from above */}
                <a className="btn badge" onClick={logout}>
                    Logout
                </a>
            </div>
        );
    } else {
        return (
            <a className="btn badge" href="/register">
                Register
            </a>
        );
    }
}