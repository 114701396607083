import dayjs from "dayjs";
import {deleteWithToken, getWithToken, postWithToken} from "../../Services/DataSettings";

let currentWorkday;

export const Workday = ({date, weekday, workdays: selectedWorkdays}) => {
    let selectedClass = '';
    currentWorkday = selectedWorkdays.find((selectedWorkday) =>
        dayjs(selectedWorkday.date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD"),
    );

    if (currentWorkday === undefined) {
        currentWorkday = {
            _id: null,
            Date: date,
        };
    }else{
        selectedClass = 'workday-set';
    }

    return (
        <button
            onClick={(e) => clickHandler(e)}
            title={dayjs(currentWorkday.Date).format("YYYY-MM-DD")}
            className={`workday ${selectedClass}`}
            id={currentWorkday._id}
        >
            {weekday}
        </button>
    );
};

const getUserId = async () => {
    try{
        const response = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/verifiedUsers`);

        const data = await response.json();

        if (response.ok){
            return data._id;
        }else{
            console.error('Fehler bei der Übergabe der ID:', data);
            return null;
        }
    }catch (e) {
        console.error('Fehler beim UserID holen:', e);
        return null;
    }
}

const clickHandler = async (e) => {
    if (e.target.classList.contains("saving")) return;
    if (e.target.classList.contains("workday-set")) {
        await removeDate(e);
    } else {
        const currentWorkday = {
            user_id: await getUserId(),
            Date: e.target.title,
        };
        await addDate(e, currentWorkday);
    }
};

const addDate = async (e, currentWorkday) => {
    try {
        e.target.classList.add("saving");

        const response = await postWithToken(`${process.env.REACT_APP_BACKEND_URL}/workdays/create`,{
            date: dayjs(currentWorkday.Date).format("YYYY-MM-DD"),
            user_id: await getUserId()
        });

        const result = await response.json();

        if (response.ok) {
            currentWorkday._id = result._id;
            currentWorkday.user_id = result.user_id;
            e.target.id = result._id;

            e.target.classList.add("workday-set");
        } else {
            console.error('Failed fetching');
            e.target.classList.add("error");
        }
    } catch (error) {
        console.error('Error:', error);
        e.target.classList.add("error");
    }finally {
        e.target.classList.remove("saving");
    }

};

const removeDate = async (e) => {
    try {
        e.target.classList.add("saving");

        const response = await deleteWithToken(`${process.env.REACT_APP_BACKEND_URL}/workdays/${e.target.id}`);

        if (response.ok){
            e.target.classList.remove("workday-set");
            e.target.removeAttribute("_id");
        }else{
            console.error("Can't delete");
        }
    }catch (error) {
        console.error('Error:', error);
    }finally {
        e.target.classList.remove("saving");
    }
};