import React from "react";
import {useEffect, useState} from "react";
import {getWithToken, useFetchUserData} from "../../Services/DataSettings";

export const Details = ({plannedtime, totaltime, date}) => {
    const {userId} = useFetchUserData();
    const [error, setError] = useState('');
    const convertPlannedTimeFromMinutesToHours = (minutes) => {
        if (!minutes) return '0.00h';

        const convertedMinutes = minutes / 60;

        return `${convertedMinutes.toFixed(2)}h`;
    };

    const calculateTimeDifference = (total, plan) => {
        if (!total || !plan) return '0.00h';

        const planInHours = plan / 60;

        const differences = parseFloat(total) - planInHours;


        return `${differences.toFixed(2)}h`;
    }

    const outputTotal = (total) => {
        if (!total) return '0.00h';

        return `${total}`;
    }

    const downloadCSV = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/timetracker/download/${userId}`);

            if (!res.ok) {
                console.error({error: 'Failed to download CSV'});
                setError('Download fehlgeschlagen');
            }

            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }catch (e) {
            console.error({error: e.message});
            setError(e.message);
        }
    }

    useEffect(() => {
        renderDetails();
    }, [date, plannedtime, totaltime]);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, []);

    const renderDetails = () => {
        if (error) return <>{error}</>
        return (
            <>
                <div className="time-details">
                    <span>Geplante Zeit:</span><span>{convertPlannedTimeFromMinutesToHours(plannedtime)}</span>

                    <span>Arbeitszeit:</span><span>{outputTotal(totaltime)}</span>

                    <span>Differenz:</span>
                    <span className={calculateTimeDifference(totaltime, plannedtime).startsWith('-') ? 'positive' : 'negative'}>{calculateTimeDifference(totaltime, plannedtime)}</span>
                </div>
                <button className="download" onClick={downloadCSV}>Herunterladen</button>
            </>
        );
    };

    return renderDetails();
}