import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {LoginCheck, postWithToken} from "../../Services/DataSettings";

/**
 * sends the to be added data to the backend
 *
 * @param userData - the data which is sent to the backend
 */
async function createUser(userData) {
    return postWithToken(`${process.env.REACT_APP_BACKEND_URL}/admin/createUser`, userData);
}
export const CreateUsers = () => {
    const isLoggedIn = LoginCheck();
    const navigate = useNavigate();

    const [specialisation, setSpecialisation] = useState([]);
    const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
    const [email, setEmail] = useState("");
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [error, setError] = useState("");
    const [passwordValidation, setPasswordValidation] = useState({
        minValueValidation: false,
        numberValidation: false,
        capitalLetterValidation: false,
        specialCharacterValidation: false,
    });

    /**
     * gets all specialisations from the database
     */
    const getSpecialisations = async () => {
        try{
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/specialisations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!data || (data.code < 200 || data.code >= 300)){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting specialisations for creating users"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setSpecialisation(data);
        }catch (e) {
            console.error({error: e.message});
            setError("Da lief etwas falsch beim Holen der Spezialisierungsdaten");
        }
    };

    /**
     * gets all the roles from the database
     */
    const getRoles = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/roles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();

            if (!data || (data.code < 200 || data.code >= 300)){
                console.error({error: "Something went wrong while fetching data"});
                setError('Etwas ist schief gelaufen beim Holen der Daten')
            }

            if (data.code === 401){
                console.error({error: `Authentication problem.`})
                setError("Nicht authentifiziert für diese Seite");
                window.location.href = "/401";
            }

            if (data.code === 403){
                console.error({error: `Authorization problem.`})
                setError("Nicht autorisiert für diese Seite");
                window.location.href = "/403";
            }

            if (data.code === 500){
                console.error({error: "Server Error on admin page getting roles for creating users"})
                setError("Da lief was schief auf unserem Ende");
                window.location.href = "/500";
            }

            setRoles(data);
        }catch (e) {
            console.error({error: "Something went wrong fetching roles data"});
            setError("Da lief etwas falsch beim Holen der Rollendaten");
        }
    };

    //loads data once until successful or based on dependency
    useEffect(() => {
        getRoles();
    }, []);
    useEffect(() => {
        getSpecialisations();
    }, []);
    useEffect(() => {
        if (error) {
            alert("Da ist etwas schief gelaufen. Bitte melde dich beim Lehrer oder beim IT-Support mit folgender Fehlermeldung: " + error);
        }
    }, [error]);
    useEffect(() => {
        if (isLoggedIn) {
            getSpecialisations();
            getRoles();
        } else {
            setError("Der Benutzer ist nicht eingeloggt");
        }
    }, [isLoggedIn]);

    /**
     * looks if the validation is correct through a regex pattern
     *
     * @param password - the password of the user
     */
    const validatePassword = (password) => {
        setPasswordValidation({
            minValueValidation: password.length >= 8,
            numberValidation: /\d/.test(password),
            capitalLetterValidation: /[A-Z]/.test(password),
            specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
        });
    };

    /**
     * on password change the new password is validated and changed
     *
     * @param e - event handler
     */
    const handlePasswordSafety = (e) => {
        const checkedPassword = e.target.value;
        setPassword(checkedPassword);
        validatePassword(checkedPassword);
    };

    /**
     * form handler for creating a user
     *
     * @param e - event handler
     */
    const handleCreate = async (e) => {
        e.preventDefault();

        const isCreated = await createUser({
            selectedSpecialisation,
            email,
            selectedRoles,
            password,
            name,
            surname,
        });

        if (isCreated) {
            console.log({message: "User created"});
            alert("User wurde erfolgreich erstellt");
        } else {
            console.error({error: "User couldn't be created"});
            setError("User konnte erstellt werden");
        }
    };

    //checks for error and returns HTML body
    if (error){
        return <>Fehler: {error}</>
    }else{
        return (
            <>
                <div className="module-creation__container">
                    <div className="row">
                        <div className="administration col-1 col-md-2"><img className="login-img" src="/modules.gif"
                                                                            alt="modules"/></div>
                        <div className="administration col-10 col-md-4">
                            <h1>Nutzer erstellen</h1>
                            <form onSubmit={handleCreate}>
                                <h5>Vorname *</h5>
                                <input type="text" onChange={(e) => setName(e.target.value)} required/>

                                <h5>Nachname *</h5>
                                <input type="text" onChange={(e) => setSurname(e.target.value)} required/>

                                <h5>Ausbildung *</h5>
                                <select value={selectedSpecialisation}
                                        onChange={(e) => setSelectedSpecialisation(e.target.value)} required>
                                    <option value="">Ausbildung aussuchen</option>
                                    {specialisation.map((specialisations) => (
                                        <option key={specialisations._id} value={specialisations._id}>
                                            {specialisations.specialisation}
                                        </option>
                                    ))}
                                </select>

                                <h5>Rolle *</h5>
                                <select value={selectedRoles} onChange={(e) => setSelectedRoles(e.target.value)} required>
                                    <option value="">Rolle aussuchen</option>
                                    {roles.map((role) => (
                                        <option key={role._id} value={role._id}>
                                            {role.role}
                                        </option>
                                    ))}
                                </select>

                                <h5>E-Mail *</h5>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} required/>

                                <h5>Passwort *</h5>
                                <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}" onChange={handlePasswordSafety} required/>

                                <p> Alle mit * markierten Felder müssen ausgefüllt werden</p>

                                {Object.entries(passwordValidation).map(([key, value]) => (
                                    <div key={key}>
                                        {value ? (
                                            <>
                                                ✔️
                                            </>
                                        ) : (
                                            <>
                                                ❌
                                            </>
                                        )}
                                        {key === 'minValueValidation' && 'Passwort muss mindestens 8 Zeichen lang sein'}
                                        {key === 'numberValidation' && 'Passwort muss mindestens eine Zahl enthalten'}
                                        {key === 'capitalLetterValidation' && 'Passwort muss muss mindestens einen Grossbuchstaben haben'}
                                        {key === 'specialCharacterValidation' && 'Passwort muss mindestens ein besonderes Zeichen enthalten'}
                                    </div>
                                ))}

                                <br/><br/>

                                <button type="submit">Speichern</button>
                                <button className="go-back" onClick={() => navigate('/administration/users')}>Zurück zur Liste</button>
                            </form>
                        </div>
                        <div className="administration col-1 col-md-2"/>
                    </div>
                </div>
            </>
        );
    }
}