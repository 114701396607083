import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {fetchWithToken, getWithToken} from "../Services/DataSettings";

export function Moduldetail({ cards, saveModulecard, onCardChange }){
    const [rerender, setRerender] = useState(false);

    const [mods, setMods] = useState([]);
    const [modulecard, setModulecard] = useState(cards);
    const [startdate, setStartdate] = useState(
        dayjs(cards.startdate).format("YYYY-MM-DD")
    );
    const [enddate, setEnddate] = useState(
        dayjs(cards.enddate).format("YYYY-MM-DD")
    );

    const [assessment, setAssessment] = useState(cards.assessment?cards.assessment:"Nicht definiert");
    const [sequence, setSequence] = useState(cards.sequence?cards.sequence:"Nicht definiert");
    const [modId, setModId] = useState(cards.mod_for_student?cards.mod_for_student:null);

    const saveAgreement = (e) => {
        e.preventDefault();
        if (modId === "" || modId == null){
            console.log('Tried to save without defining a teacher');
            alert('Bitte wähle einen Lehrer aus, bevor du speicherst.');
            return;
        }

        const body = {
            startdate: startdate,
            enddate: enddate,
            assessment: assessment,
            sequence: sequence,
            mod_for_student: modId,
        };

        saveModulecard(modulecard._id, body);
        modulecard.startdate = dayjs(startdate).format("YYYY-MM-DD");
        modulecard.enddate = dayjs(enddate).format("YYYY-MM-DD");
        modulecard.assessment = assessment;
        modulecard.sequence = sequence;
        modulecard.mod_for_student = modId;
        onCardChange(modulecard);

        setRerender(!rerender);
    };

    const loadModerators = async () => {
        try{
            const res = await getWithToken(`${process.env.REACT_APP_BACKEND_URL}/users/teacher-choice`);

            const data = await res.json();

            if (!res.ok){
                console.log("Couldn't fetch the data");
            }

            setMods(data);
        }catch (e) {
            console.error({ error: e.message });
        }
    };

    useEffect(() => {
        setStartdate(cards.startdate?dayjs(cards.startdate).format("YYYY-MM-DD"):"");
        setEnddate(cards.enddate?dayjs(cards.enddate).format("YYYY-MM-DD"):"");
        setAssessment(cards.assessment?cards.assessment:"");
        setSequence(cards.sequence?cards.sequence:"");
        setModId(cards.mod_for_student?cards.mod_for_student:null);

        setModulecard(cards);
        renderDetail();
    }, [rerender, cards]);

    useEffect(() => {
        loadModerators();
    }, []);

    useEffect(() => {
        renderDetail();
    }, [modulecard._id]);

    const renderDetail = () => {
        if (modulecard === undefined || Object.keys(modulecard).length === 0 || modulecard.length === 0){
            return <></>;
        }else{
            return(
                <>
                    <div className="detail detail-overlay">
                        <button
                            onClick={() => {setModulecard([]);onCardChange([]);}}
                            className="btn closebtn"
                        >
                            X
                        </button>
                        <h2>Modulinformationen</h2>

                        <div className="content">
                            <h3>
                                {modulecard.module_id.number} {modulecard.module_id.name}
                            </h3>
                            <h4>Objekt:</h4><p>{modulecard.module_id.object}</p>
                            <h4>Kompetenz:</h4><p> {modulecard.module_id.skill}</p>
                            <div className="row">
                                <div className="col-6">
                                    <h5>Handlungskompetenzbereich:</h5><p>{modulecard.module_id.actionskills}</p>
                                </div>
                                <div className="col-6">
                                    <h5>Bemerkungen:</h5><p>{modulecard.module_id.remarks}</p>
                                </div>
                                <div className="col-3">
                                    <h5>Lehrjahr:</h5><p>{modulecard.module_id.year}</p>
                                </div>
                                <div className="col-3">
                                    <h5>Typ:</h5><p> {modulecard.module_id.type}</p>
                                    <p>{modulecard.module_id.state}</p>
                                </div>
                            </div>
                            <a
                                href={`https://www.modulbaukasten.ch/module/${modulecard.module_id.number}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Modulidentifikation ICT CH
                            </a>

                            {/*<Dependencies dependencies={modulecard.module_id.dependencies}/>*/}

                            <h2>Modulvereinbarung</h2>
                            <div></div>
                            <form className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="startdate">Startdatum</label>
                                    <input
                                        onChange={(e) => {
                                            if (e.target.value !== "") setStartdate(e.target.value);
                                        }}
                                        type="date"
                                        name="startdate"
                                        className="form-control"
                                        value={startdate}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="enddate">Abgabedatum</label>
                                    <input
                                        onChange={(e) => {
                                            if (e.target.value !== "") setEnddate(e.target.value);
                                        }}
                                        type="date"
                                        name="enddate"
                                        className="form-control"
                                        id="enddate"
                                        value={enddate}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="assessment">
                                        Form und Abmachungen bezüglich der Leistungsbeurteilung (LB
                                        und OBA)
                                    </label>

                                    <textarea
                                        onChange={(e) => {
                                            setAssessment(e.target.value);
                                        }}
                                        type="textarea"
                                        name="assessment"
                                        className="form-control"
                                        id="assessment"
                                        value={assessment}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="sequence">
                                        Grober Ablauf (Feedback/Termine...)
                                    </label>
                                    <textarea
                                        onChange={(e) => {
                                            setSequence(e.target.value);
                                        }}
                                        type="textarea"
                                        name="sequence"
                                        className="form-control"
                                        id="sequence"
                                        value={sequence}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="mod">Lehrer</label>

                                    <select value={modId} onChange={(e) => {
                                        setModId(e.target.value);
                                    }}>
                                        <option key="none" value="">Nicht ausgewählt</option>
                                        {mods.map((moderator) => (
                                            <option key={moderator._id} value={moderator._id}>{moderator.name} {moderator.surname}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <button
                                        onClick={() => {setModulecard([]);onCardChange([]);}}
                                        type="button"
                                        className="col-12 col-md-6 btn btn-cancel"
                                    >
                                        Abbrechen
                                    </button>
                                    <button
                                        onClick={saveAgreement}
                                        type="submit"
                                        className="col-12 col-md-6 btn btn-save"
                                    >
                                        Speichern
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            );
        }
    };

    return renderDetail();
}
